import Vue from 'vue';

/**
 * Класс маршрутизации переходов
 */
class Router {
    /**
     * Инициализируем параметры и поведение маршрута по умолчанию
     */
    constructor() {
        this._routes = [];
        this._handler = ( router, parameters = {} ) => {
            let route = router.routes[ 0 ];

            if ( !route.props ) {
                Vue.router.push( { name: route.name } );
                return Router.response( true );
            }

            Vue.router.push( { name: route.name, params: parameters } );
            return Router.response( true );
        };
    }

    /**
     * Переход к маршруту по определенным параметрам
     *
     * @param {object} parameters
     * @return {*}
     */
    go( parameters = {} ) {
        let handler = this._handler( this, parameters );

        return handler instanceof Promise ? handler.catch( error => { throw error; } ) : handler;
    }

    /**
     * Получаем маршрут/маршруты
     *
     * @return {array|*}
     */
    get routes() {
        return this._routes;
    }

    /**
     * Устанавливаем маршрут/маршруты
     *
     * @param {array} value
     */
    set routes( value ) {
        this._routes = value;
    }

    /**
     * Устанавливаем обработчик маршрута
     *
     * @param {function} value
     */
    set handler( value ) {
        if ( typeof value === 'function' ) {
            this._handler = value;
        }
    }

    /**
     * Выбрасываем иключение с запретом доступа
     *
     * @throws {{ transition: true, data: { forbidden: true } }}
     */
    static forbidden() {
        throw Router.response( true, { forbidden: true } );
    }

    /**
     * Выбрасываем кастомный error без дальнейшей обработки
     *
     * @param {boolean} transition
     * @param {object} data
     * @throws {{ transition: {boolean}, data: {object} }}
     */
    static error( transition, data = null ) {
        throw Router.response( transition, data, true );
    }

    /**
     * Получаем универсальный ответ обработки маршрута
     *
     * флаг transition определяет является ли данное сообщение ответом от перехода.
     * аргумент data определяет набор данных.
     * флаг stop определяет будет ли выполнена обработка ответа в обработчике перехода (при обработке ошибок).
     *
     * <strong>
     *     Обязательное использование response() в любом ответе перехода
     * </strong>
     *
     * @param {boolean} transition
     * @param {object} data
     * @param {boolean} stop
     * @return {object}
     */
    static response( transition, data = null, stop = false ) {
        return { transition, stop, data };
    }
}

export default Router;
