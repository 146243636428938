import {ROLE_ACCOUNTANT, ROLE_ADMIN, ROLE_LABELER, ROLE_STOCKMAN} from "@utils/Roles";

export default [
    {
        name: 'ShipmentHistory',
        path: '/storage/shipment_history',
        component: () => import('@components/storage/ShipmentHistory'),
        meta: {
            label: 'Склад / Список накладных',
            auth: [ROLE_ADMIN, ROLE_STOCKMAN, ROLE_ACCOUNTANT]
        }
    },
    {
        name: 'DctShipment',
        path: '/storage/shipment_dct',
        component: () => import('@components/storage/DctShipment'),
        meta: {
            label: 'Склад / Формирование накладной',
            auth: [ROLE_ADMIN, ROLE_STOCKMAN]
        }
    },
    {
        name: 'ReadyToShip',
        path: '/storage/pallets_ready',
        component: () => import('@components/storage/ReadyToShip'),
        meta: {
            label: 'Склад / Готовое к отгрузке',
            auth: [ROLE_ADMIN, ROLE_STOCKMAN]
        }
    },
    {
        name: 'PalletMovement',
        path: '/storage/pallet_movement',
        component: () => import('@components/storage/PalletMovement'),
        meta: {
            label: 'Склад / Перемещение паллет',
            auth: [ROLE_ADMIN, ROLE_STOCKMAN, ROLE_LABELER]
        }
    },
    {
        name: 'PalletCells',
        path: '/storage/pallet_cells',
        component: () => import('@components/storage/PalletCells'),
        meta: {
            label: 'Склад / Цех сбора паллет',
            auth: [ROLE_ADMIN, ROLE_STOCKMAN, ROLE_LABELER]
        }
    },
    {
        name: 'Storages',
        path: '/storages/',
        component: () => import('@views/storages/Index.vue'),
        meta: {
            label: 'Склады и цеха',
            auth: [ROLE_ADMIN]
        }
    },
    {
        name: 'StorageEdit',
        path: '/storages/:id',
        component: () => import('@views/storages/Form.vue'),
        meta: {
            label: 'Склады и цеха / Редактирование',
            auth: [ROLE_ADMIN]
        }
    },
]
