import {
    ROLE_ADMIN,
    ROLE_STOCKMAN,
    ROLE_STORAGE_SENIOR,
    ROLE_SHIFT_SUPERVISOR,
    ROLE_ACCOUNTANT,
    ROLE_STOCKMAN_SENIOR,
    ROLE_LABELER
} from "@utils/Roles";

export default [
    {
        name: 'ProductionTaskList',
        path: '/production-tasks',
        component: () => import('@components/productiontask/ProductionTaskList'),
        meta: {
            label: 'Сменные задания',
            auth: [ROLE_ADMIN, ROLE_STORAGE_SENIOR, ROLE_SHIFT_SUPERVISOR, ROLE_LABELER]
        }
    },
    {
        name: 'ProductionTaskListPlans',
        path: '/production-tasks/plans/:planId?',
        component: () => import('@components/productiontask/ProductionTaskListPlans'),
        props: true,
        meta: {
            label: 'Сменные задания по планам',
            auth: [ROLE_ADMIN, ROLE_STORAGE_SENIOR, ROLE_SHIFT_SUPERVISOR, ROLE_LABELER]
        }
    },
    {
        name: 'ShipmentTaskList',
        path: '/production-tasks/shipment',
        component: () => import('@components/productiontask/ShipmentTaskList'),
        meta: {
            label: 'Задания на отгрузку',
            auth: [ROLE_ADMIN, ROLE_STOCKMAN, ROLE_SHIFT_SUPERVISOR, ROLE_ACCOUNTANT]
        }
    },
    {
        name: 'StorageTaskList',
        path: '/tasks/storage',
        component: () => import('@components/productiontask/StorageTaskList'),
        meta: {
            label: 'Склад / Сменные задания',
            auth: [ROLE_ADMIN, ROLE_STOCKMAN, ROLE_SHIFT_SUPERVISOR]
        }
    },
    {
        name: 'ProductionTaskDashboard',
        path: '/production-tasks/dashboard',
        component: () => import('@components/productiontask/Dashboard/Dashboard'),
        meta: {
            auth: [ROLE_ADMIN, ROLE_STORAGE_SENIOR, ROLE_SHIFT_SUPERVISOR, ROLE_STOCKMAN_SENIOR, ROLE_LABELER]
        }
    },
    {
        name: 'StorageSeniorDashboard',
        path: '/storage-senior-dashboard',
        component: () => import('@components/productiontask/StorageSeniorDashboard/Dashboard'),
        meta: {
            label: 'Рабочий стол',
            auth: [ROLE_ADMIN, ROLE_STORAGE_SENIOR]
        }
    },
]
