import Vue from 'vue'
import { NOMENCLATURES_ENDPOINT } from "@utils/endpoints";

export default {
    namespaced: true,
    state: {
        data: {}
    },
    getters: {
        info(state) {
            return nomenclature_id => state.data[nomenclature_id]
        },
        image(state, getters) {
            return nomenclature_id => getters.info(nomenclature_id)
                ? getters.info(nomenclature_id).photo_url ?? getters.info(nomenclature_id).prototype_photo_image
                : null
        }
    },
    mutations: {
        setInfo(state, {nomenclature_id, info}) {
            Vue.set(state.data, nomenclature_id, info)
        }
    },
    actions: {
        getNomenclatureInfo({commit, getters}, nomenclature_id) {
            return new Promise((resolve, reject) => {
                if (getters.info(nomenclature_id)) {
                    resolve(getters.info(nomenclature_id))
                } else {
                    Vue.http.get(`${NOMENCLATURES_ENDPOINT}/${nomenclature_id}/info`, {
                            params: {
                                without_loading: 1
                            }
                        })
                        .then(response => {
                            commit('setInfo', {
                                nomenclature_id,
                                info: response.body
                            })

                            resolve(response.body)
                        }, reject)
                }
            })
        }
    }
}
