<template>
    <div style="display: flex; justify-content: space-between">
        <div class="mr-2" style="font-size: 24px;"><b>{{time}}</b></div>
        <storage-picker class="mr-1"
                        v-model="filter.storage"
                        :selectable-for-task-list="true"
                        :disabled="loading"
                        :nullable="true"
                        :allowed-roles="[ROLE_STORAGE_SENIOR, ROLE_GROUP_SENIOR]"></storage-picker>
        <datetime :disabled="loading" v-model="filter.date" type="date" class="mr-1 form-control no-border"></datetime>
        <shift-input :disabled="loading" v-model="filter.shift" class="mr-1"></shift-input>
        <button :disabled="loading" @click="apply" class="btn btn-sm btn-outline-success">
            <i v-if="loading" class="fas fa-spinner fa-spin"></i>
            <i v-else class="fas fa-check"></i>
        </button>
    </div>
</template>

<script>
    import StoragePicker from "../../_common/StoragePicker";
    import ShiftInput from '../../_common/ShiftInput';
    import {Datetime} from 'vue-datetime';
    import moment from 'moment';
    import {PRODUCTION_SHIFT_ENDPOINT} from "@utils/endpoints";
    import {ROLE_STORAGE_SENIOR, ROLE_GROUP_SENIOR} from "@utils/Roles";

    export default {
        name: "HeaderToolbar",
        components: {
            StoragePicker,
            datetime: Datetime,
            ShiftInput,
        },
        computed: {
            storage() {
                return this.$store.state.storage_senior_dashboard.storage;
            },
            actualShift() {
                return this.$store.state.storage_senior_dashboard.actualShift;
            },
        },
        watch: {
            storage(val) {
                this.filter.storage = val;
            },
            actualShift(val) {
                this.filter.date = this.getDate(val);
                this.filter.shift = val.shift ? val.shift : null;
            }
        },
        data() {
            return {
                ROLE_STORAGE_SENIOR,
                ROLE_GROUP_SENIOR,
                filter: {
                    date: null,
                    storage: null,
                    shift: null,
                },
                loading: false,
                time: null,
            };
        },
        methods: {
            moment,
            setStorage(storage) {
                this.$store.commit('storage_senior_dashboard/set_storage', storage);
            },
            getDate(ActualShift) {
                return moment(ActualShift.date ? ActualShift.date : null).format('YYYY-MM-DDTHH:mm:ss')+'.000Z'; // Некий костыль, который решает траблу
            },
            setDate(date) {
                this.$store.commit('storage_senior_dashboard/set_date', date);
            },
            apply() {
                if (this.loading) {
                    return;
                }

                if (!(!!this.filter.date) || !(!!this.filter.shift)) {
                    return;
                }

                this.loading = true;
                this.$http.get(PRODUCTION_SHIFT_ENDPOINT, {
                    params: {
                        date: moment(this.filter.date).format('YYYY-MM-DD'),
                        shift_id: this.filter.shift.id,

                        without_loading: true,
                    }
                }).then(response => {
                    this.$store.commit('storage_senior_dashboard/set_actual_shift', response.data);
                    this.$store.commit('storage_senior_dashboard/set_storage', this.filter.storage);
                    this.$store.dispatch('storage_senior_dashboard/reload');
                }).catch(response => this.$root.responseError(response, 'Ошибка определения смены'))
                    .finally(() => {
                    this.loading = false;
                });
            }
        },
        mounted() {

            this.filter.storage = this.storage;
            if (this.actualShift) {
                this.filter.date = this.getDate(this.actualShift);
                this.filter.shift = this.actualShift.shift;
            }

            setInterval(() => {
                this.time = moment().format('HH:mm');
            }, 1000);
        },
    }
</script>

<style>
    .no-border input {
        border:none;
    }
</style>
