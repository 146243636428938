'use strict';

import {STORAGE_ENDPOINT} from '@utils/endpoints.js';
import HttpClient         from './http-client';

class StorageService {
    constructor() {
        this.uri = STORAGE_ENDPOINT;
    }

    async index(params = {}) {
        params.limit = 100;
        params.without_loading = true;
        const response = await HttpClient.get(this.uri, {params});

        return response.data.data;
    }
}

export default new StorageService();
