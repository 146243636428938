<template>
    <modal
        id="login-modal"
        name="login"
        height="auto"
        :adaptive="true"
        :clickToClose="false"
        @before-open="beforeOpen"
        @opened="opened"
        @closed="closed"
    >
        <form autocomplete="off" @submit.prevent="login" method="post">
            <div class="modal-content p-2">
                <div class="modal-header">
                    <h4 class="modal-title">GF Производство</h4>
                </div>
                <div class="modal-body">
                    <p class="text-muted text-center">Вход в аккаунт</p>
                    <b-input-group class="mb-3">
                        <input class="form-control" placeholder="Логин" v-model="email" required />
                    </b-input-group>
                    <b-input-group class="mb-4">
                        <input
                            type="password"
                            class="form-control"
                            placeholder="Пароль"
                            v-model="password"
                            required
                        />
                    </b-input-group>
                    <b-alert show variant="danger" v-if="error">{{error}}</b-alert>
                    <b-alert show variant="success" v-if="success">{{success}}</b-alert>
                </div>
                <div class="modal-footer">
                    <b-button type="submit" variant="primary" class="px-4">Войти</b-button>
                </div>
            </div>
        </form>
    </modal>
</template>

<script>
    export default {
        name: "LoginModal",
        data() {
            return {
                email: null,
                password: null,
                error: null,
                success: null
            }
        },
        methods: {
            login() {
                this.error = null
                this.success = null
                const prevUser = this.$auth.watch.data
                this.$auth.login({
                    body: {
                        email: this.email,
                        username: this.email,
                        password: this.password
                    },
                    redirect: false,
                    success() {
                        const nextUser = this.$auth.watch.data
                        this.loginCallback(prevUser, nextUser)
                    },
                    error() {
                        this.errorCallback()
                    }
                })
            },
            onBarcodeScanned(data) {
                this.error = null
                this.success = null
                const prevUser = this.$auth.watch.data
                this.$auth.login({
                    url: "v2/barcode_login",
                    body: {
                        login_barcode: data.barcode
                    },
                    redirect: false,
                    success() {
                        const nextUser = this.$auth.watch.data
                        this.loginCallback(prevUser, nextUser)
                    },
                    error() {
                        this.errorCallback()
                    }
                })
            },
            loginCallback(prevUser, nextUser) {
                this.success = "Login successful"
                this.$modal.hide('login');
                this.$bvModal.hide('barcode-modal');
                if (prevUser && prevUser.id !== nextUser.id) {
                    this.$router.push("/welcome")
                }
            },
            errorCallback() {
                this.error = "Failed to login"
            },
            beforeOpen() {
                this.error = null
                this.success = null
            },
            opened() {
                this.$root.listenToBarcodeScanning(this)
            },
            closed() {
                this.$root.restorePreviousBarcodeScanning()
            }
        }
    }
</script>

<style scoped>
    .modal-header {
        border-bottom: none;
        padding-bottom: 0.5rem;
        justify-content: center;
    }
    .modal-body {
        padding-top: 0;
        padding-bottom: 0;
        justify-content: center;
    }
    .modal-footer {
        border-top: none;
        padding-top: 0;
        justify-content: center;
    }
</style>

<style>
    #login-modal {
        z-index: 10000;
        background: rgba(0, 0, 0, 0.5);
    }
    #login-modal .v--modal {
        text-align: center;
        background-color: transparent;
    }
</style>
