import { ROLE_ADMIN, ROLE_STORAGE_SENIOR } from '@utils/Roles';

export default [
    {
        name: 'ProductionTasksReportDashboard',
        path: '/report/production-tasks/dashboard',
        component: () => import('@components/production-tasks-report/ProductionTasksReportDashboard'),
        meta: {
            label: 'Отчет по сменным заданиям',
            auth: [ ROLE_ADMIN, ROLE_STORAGE_SENIOR ]
        }
    }
];
