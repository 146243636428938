'use strict';

export default [
    {
        title: 'Рабочий стол',
        to: {name: 'StorageSeniorDashboard'},
        icon: 'fa fa-desktop'
    },
    {
        title: 'Цех',
        icon: 'fa fa-industry',
        children: [
            {
                title: 'Сменные задания',
                to: {name: 'ProductionTaskDashboard'},
                icon: 'fa fa-clipboard-list'
            },
            {
                title: 'Сборка ящиков',
                to: {name: 'Packing'},
                icon: 'fa fa-inbox'
            }
        ]
    },
    {
        title: 'Передать',
        to: {name: 'MaterialTransferActList'},
        icon: 'fa fa-sign-out-alt',
        badge: 'materialTransferActsCreatedByQualityControlAct',
    },
    {
        title: 'Принять',
        to: {name: 'MaterialTransferActReceiveList'},
        icon: 'fa fa-sign-in-alt',
        badge: 'availableToReceiveCount'
    },
    {
        title: 'Лотки и этикетки',
        to: {name: 'PackTransferInterface'},
        icon: 'fa fa-box-open'
    },
    {
        title: 'Контроль качества',
        icon: 'fa fa-not-equal',
        children: [
            {
                title: 'Акты контроля качества',
                to: {name: 'QualityControlActList'},
                icon: 'fa fa-check-square'
            },
            {
                title: 'Проверка санитарных норм',
                to: {name: 'SanitaryInspectionActList'},
                icon: 'fa fa-clipboard'
            },
            {
                title: 'Запланированные проверки',
                to: {name: 'PlannedSanitaryInspections'},
                icon: 'fas fa-clipboard-list',
                badge: 'plannedSanitaryInspectionCount',
            },
            {
                title: 'Долгосрочные задачи саннорм',
                to: {name: 'LongTermSanitaryInspections'},
                icon: 'far fa-clock',
            },
            {
                title: 'Планирование проверок',
                to: {name: 'Planning'},
                icon: 'fas fa-clipboard-list',
            }
        ]
    },
    {
        title: 'Остатки',
        to: {name: 'StorageReportIndex'},
        icon: 'fa fa-clipboard'
    },
    {
        title: 'Инвентаризация',
        to: {name: 'InventoryActList'},
        icon: 'fa fa-clipboard-list'
    },
    {
        title: 'НОСП',
        icon: 'fa fa-user-shield',
        children: [
            {
                title: 'Отчет по сырью',
                to: {name: 'ShiftUsage'},
                icon: 'fa fa-clipboard'
            },
            {
                title: 'Отчет по сменным заданиям',
                to: {name: 'ProductionTasksReportDashboard'},
                icon: 'fa fa-clipboard'
            }
        ]
    },
    {
        title: 'Склад',
        icon: 'fa fa-warehouse',
        children: [
            {
                title: 'Заказы поставщику',
                to: {name: 'OrderToContractorActList'},
                icon: 'fa fa-box'
            },
            {
                title: 'Снабжение',
                to: {name: 'AdmissionActList'},
                icon: 'fa fa-truck'
            },
            {
                title: 'Возвраты',
                to: {name: 'CorrectionActs'},
                icon: 'fa fa-undo',
                badge: 'approvedCorrectionActCount',
            },
            {
                title: 'Сменные задания',
                to: {name: 'StorageTaskList'},
                icon: 'fa fa-tasks'
            },
            {
                title: 'Цех сбора паллет',
                to: {name: 'PalletCells'},
                icon: 'fa fa-pallet'
            },
            {
                title: 'Перемещение паллет',
                to: {name: 'PalletMovement'},
                icon: 'fa fa-pallet'
            },
            {
                title: 'Готовое к отгрузке',
                to: {name: 'ReadyToShip'},
                icon: 'fa fa-clock'
            },
            {
                title: 'Отгрузка (ТСД)',
                to: {name: 'DctShipment'},
                icon: 'fa fa-dolly-flatbed'
            },
            {
                title: 'Отгрузка',
                to: {name: 'ShipmentTaskList'},
                icon: 'fa fa-dolly-flatbed'
            },
            {
                title: 'Накладные',
                to: {name: 'ShipmentHistory'},
                icon: 'fa fa-file-invoice'
            }
        ]
    },
    {
        title: 'Планирование',
        icon: 'fa fa-map',
        children: [
            {
                title: 'График',
                to: {name: 'ProductionTaskGanttChart'},
                icon: 'fas fa-project-diagram'
            },
            {
                title: 'Календарь по цехам',
                to: {name: 'ProductionTaskSchedule'},
                icon: 'fa fa-calendar'
            },
            {
                title: 'Заказы на производство',
                to: {name: 'ProductionOrderDashboard'},
                icon: 'fa fa-map'
            },
            {
                title: 'Планы производства',
                to: {name: 'ProductionPlanList'},
                icon: 'fa fa-map'
            },
        ]
    },
    {
        title: 'Отчетность',
        icon: 'fa fa-file-medical-alt',
        children: [
            {
                title: 'По номенклатурам',
                to: {name: 'TemperatureAfterHotStorageReports'},
                icon: 'fa fa-drumstick-bite'
            },
            {
                title: 'По t, после шока',
                to: {name: 'TemperatureAfterShockReports'},
                icon: 'fa fa-temperature-low'
            },
            {
                title: 'Отчет по проверкам санитарных норм',
                to: {name: 'SanitaryInspectionReports'},
                icon: "fa fa-id-card"
            }
        ]
    },
    {
        title: 'Администрирование',
        icon: 'fa fa-tools',
        children: [
            {
                title: 'Пользователи',
                to: {name: 'UsersList'},
                icon: 'fa fa-users'
            },
            {
                title: 'Должности',
                to: {name: 'WorkPositionsList'},
                icon: 'fa fa-id-card'
            },
            {
                title: 'Спецификации',
                to: {name: 'SpecificationsDashboard'},
                icon: 'fa fa-grip-vertical'
            },
            {
                title: 'Табель',
                to: {name: 'TimesheetDashboard'},
                icon: 'fa fa-calendar-alt'
            },
            {
                title: 'Зарплата',
                to: {name: 'SalaryDashboard'},
                icon: 'fa fa-hand-holding-usd'
            },
            {
                title: 'Надбавки и штрафы',
                to: {name: 'SalaryBounty'},
                icon: 'fa fa-award'
            },
            {
                title: 'Настройки зарплаты',
                to: {name: 'SalarySettings'},
                icon: 'fa fa-comment-dollar'
            },
            {
                title: 'Настройки вознаграждений',
                to: {name: 'SalaryBonusList'},
                icon: 'fa fa-award'
            },
            {
                title: 'Документы',
                to: {name: 'DocumentList'},
                icon: 'fa fa-file'
            },
            {
                title: 'Импорт заказов',
                to: {name: 'ImportDashboard'},
                icon: 'fas fa-cloud-download-alt'
            },
            {
                title: 'Общие настройки',
                to: {name: 'Settings'},
                icon: 'fas fa-cog'
            },
            {
                title: 'Шаблоны чек-листов саннорм',
                to: {name: 'ChecklistTemplates'},
                icon: 'fas fa-list-ul'
            }
        ]
    },
    {
        title: 'Аналитика',
        icon: 'fa fa-chart-pie',
        children: [
            {
                title: 'Фудкост',
                to: {name: 'FoodCost'},
                icon: 'fa fa-chart-bar'
            },
            {
                title: "Стоимость операций",
                to: {name: "OperationCost"},
                icon: 'fa fa-chart-bar',
            }
        ]
    },
    {
        title: 'Еще',
        icon: 'fa fa-cog',
        children: [
            {
                title: 'Закрытие смены',
                to: {name: 'ShiftClosingActList'},
                icon: 'fa fa-unlock-alt'
            },
            {
                title: 'Подразделения',
                to: {name: 'DepartmentList'},
                icon: 'fa fa-list'
            },
            {
                title: 'Площадки',
                to: {name: 'Areas'},
                icon: 'fa fa-clone',
            },
            {
                title: 'Склады',
                to: {name: 'Storages'},
                icon: 'fa fa-list'
            },
            {
                title: 'Операции',
                to: {name: 'Operations'},
                icon: 'fa fa-list'
            },
            {
                title: 'Сервисы',
                to: {name: 'ServiceList'},
                icon: 'fa fa-toolbox'
            },
            {
                title: 'История перемещений',
                to: {name: 'MovementHistory'},
                icon: 'fa fa-history'
            },
        ]
    },
    {
        title: 'Настройки',
        icon: 'fa fa-sliders-h',
        children: [
            {
                title: 'Массы ящиков',
                to: {name: 'BoxWeightAssignment'},
                icon: 'fa fa-cube'
            },
            {
                title: 'Весы',
                to: {name: 'WeighingScalesList'},
                icon: 'fa fa-weight'
            }
        ]
    }
]

