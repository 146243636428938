let instance = null;

/**
 * Класс загрузчика (singleton)
 */
class Loader {
    /**
     * Счетчик активных загрузок
     *
     * @type {number}
     */
    semaphore = 0;

    /**
     * Возвращаем объект загрузчика
     *
     * @return {*}
     */
    constructor() {
        if ( !instance ) {
            this.discard();

            instance = this;
        }

        return instance;
    }

    /**
     * Добавляем элемент в загрузчик
     */
    add() {
        this.semaphore++;
    }

    /**
     * Проверяем состояние загрузчика
     *
     * @return {boolean}
     */
    state() {
        return this.semaphore > 0;
    }

    /**
     * Удаляем элемент из загрузчика
     */
    remove() {
        this.semaphore--;
        this.correction();
    }

    /**
     * Сбрасываем загрузчик
     */
    discard() {
        this.semaphore = 0;
    }

    /**
     * Корректируем значение счетчика активных загрузок
     */
    correction() {
        if ( this.semaphore <= 0 ) {
            this.discard();
        }
    }
}

export default new Loader();
