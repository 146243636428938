'use strict';

import {NOMENCLATURES_ENDPOINT} from '@utils/endpoints.js';
import HttpClient               from './http-client';

class NomenclatureService {
    constructor() {
        this.uri = NOMENCLATURES_ENDPOINT;
    }

    async getById(id, params = {}) {
        const response = await HttpClient.get(`${this.uri}/${id}`, {params});

        return response.data;
    }
}

export default new NomenclatureService();
