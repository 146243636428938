'use strict';

import {DOCUMENT_ENDPOINT} from '@utils/endpoints';
import HttpClient from '../http-client';

class DocumentService {
    constructor() {
        this.uri = DOCUMENT_ENDPOINT;
    }

    async getWithPagination(params) {
        return HttpClient.get(this.uri, {params});
    }

    async find(id, params) {
        return HttpClient.get(`${this.uri}/${id}`, {params});
    }

    async destroy(id) {
        return HttpClient.delete(`${this.uri}/${id}`, {});
    }

    async restore(id) {
        return HttpClient.post(`${this.uri}/${id}/restore`, {});
    }
}


export default new DocumentService();
