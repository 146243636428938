import { ROLE_ADMIN, ROLE_STOCKMAN } from '@utils/Roles';

export default [
    {
        name: 'OrderToContractorActList',
        path: '/order-to-contractor-acts',
        component: () => import('@components/ordertocontractoract/OrderToContractorActList'),
        meta: {
            label: 'Заказы поставщику',
            auth: [ ROLE_ADMIN, ROLE_STOCKMAN ],
            fullsize: true
        }
    },
    {
        props: true,
        name: 'OrderToContractorActForm',
        path: '/order-to-contractor-acts/:id',
        component: () => import('@components/ordertocontractoract/OrderToContractorActForm'),
        meta: {
            label: 'Акт заказа поставщику',
            auth: [ ROLE_ADMIN, ROLE_STOCKMAN ],
        }
    },
    {
        props: true,
        name: 'OrderToContractorActDiffReport',
        path: '/order-to-contractor-acts/:id/diff-report',
        component: () => import('@components/ordertocontractoract/OrderToContractorActDiffReport'),
        meta: {
            label: 'Отчет о расхождениях поступлений с заказом',
            auth: [ ROLE_ADMIN, ROLE_STOCKMAN ]
        }
    }
];
