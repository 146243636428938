import {$app as App}                      from '../../../../main';
import { MATERIAL_TRANSFER_ACT_ENDPOINT } from "@utils/endpoints";

export default {
    namespaced: true,
    state: {
        count: null,
    },
    getters: {
        count: state => state.count
    },
    mutations: {
        setCount(state, count) {
            state.count = count
        }
    },
    actions: {
        refresh({commit}) {
            App.$http.get(`${MATERIAL_TRANSFER_ACT_ENDPOINT}/available_to_receive`,
                {
                    params: {
                        without_loading: true,
                    }
                }
            ).then((response) => {
                commit('setCount', response.data.count);
            });
        }
    }
}
