'use strict';

import {
    ROLE_CODE_SHIFT_SUPERVISOR,
    ROLE_CODE_PLANNER,
    ROLE_CODE_GROUP_SENIOR,
    ROLE_CODE_LABELER
} from '@constants';

export default [
    {
        name: 'ProductionTaskSchedule',
        path: '/planning/production-tasks/scheduler',
        component: () => import('@views/planning/production-tasks/scheduler/Index.vue'),
        meta: {
            breadcrumb: 'Планирование / Сменные задания / Календарь по цехам',
            auth: [ROLE_CODE_SHIFT_SUPERVISOR, ROLE_CODE_PLANNER]
        }
    },
    {
        name: 'ProductionTaskGanttChart',
        path: '/planning/production-tasks/gantt-chart',
        component: () => import('@views/planning/production-tasks/gantt-chart/Index.vue'),
        meta: {
            breadcrumb: 'Планирование / Сменные задания / Диаграмма Ганта',
            auth: [ROLE_CODE_SHIFT_SUPERVISOR, ROLE_CODE_PLANNER],
            fullsize: true,
        }
    },
    {
        name: 'ProductionPlanList',
        path: '/planning/production-plans',
        component: () => import('@views/planning/production-plans/Index.vue'),
        meta: {
            breadcrumb: 'Планирование / Планы производства',
            auth: [ROLE_CODE_SHIFT_SUPERVISOR, ROLE_CODE_PLANNER],
            fullsize: true
        }
    },
    {
        name: 'ProductionPlanCreate',
        path: '/planning/production-plans',
        component: () => import('@views/planning/production-plans/Form.vue'),
        meta: {
            breadcrumb: 'Планирование / Планы производства / Создание',
            auth: [ROLE_CODE_SHIFT_SUPERVISOR, ROLE_CODE_PLANNER]
        }
    },
    {
        name: 'ProductionPlanEdit',
        path: '/planning/production-plans/:id',
        props: true,
        component: () => import('@views/planning/production-plans/Form.vue'),
        meta: {
            breadcrumb: 'Планирование / Планы производства / Редактирование',
            auth: [ROLE_CODE_SHIFT_SUPERVISOR, ROLE_CODE_PLANNER]
        }
    },
    {
        name: 'ProductionOrderDashboard',
        path: '/planning/production-orders',
        component: () => import('@components/production-order/ProductionOrderDashboard'),
        meta: {
            breadcrumb: 'Планирование / Заказы на производство',
            auth: [ROLE_CODE_GROUP_SENIOR, ROLE_CODE_LABELER, ROLE_CODE_PLANNER],
            fullsize: true
        }
    },
]
