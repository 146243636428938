<template>
    <b-modal
        @hidden='hideModal'
        title="Создание акта выпуска"
        ref='CheckCreatingProductionActModal'
        id='check-creating-production-act-modal'
    >
        <modal-error v-if="ErrorsBag.state()"></modal-error>
        <modal-spinner v-else-if="Loader.state()"></modal-spinner>

        <missing-raw-materials
            @goTo="hideModal"
            v-model="materials"
            :storage-id="storageId"
            v-show="!Loader.state() && !ErrorsBag.state()"
        ></missing-raw-materials>

        <template #modal-footer>
            <div class="w-100">
                <b-row align-h="around">
                    <b-button variant="default" size="lg" @click="hideModal" v-if="!ErrorsBag.state()">Отмена</b-button>
                    <b-button variant="success" size="lg" @click="createProductionAct" v-if="actionAccessible">
                        Начать выпуск
                    </b-button>
                </b-row>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import find from 'lodash/find';
    import cloneDeep from 'lodash/cloneDeep';

    import Loader from '../../../utils/loader';
    import ErrorsBag from '../../../utils/errorsBag';

    import ProductionActRoutes from '../../../router/production-act';
    import { PRODUCTION_TASKS_ENDPOINT } from '../../../utils/endpoints';

    import ModalError from '../../_common/ModalError';
    import ModalSpinner from '../../_common/ModalSpinner';

    import MissingRawMaterials from './bodies/MissingRawMaterials';

    export default {
        name: "CheckCreatingProductionAct",
        components: { ModalError, ModalSpinner, MissingRawMaterials },
        computed: {
            route() {
                return find( ProductionActRoutes, route => route.name === 'ProductionActUpdate' );
            },
            storageId() {
                return this.productionTask ? this.productionTask.storage_id : null;
            },
            actionAccessible() {
                return !Loader.state() && !ErrorsBag.state() && !!this.productionTask;
            }
        },
        data() {
            return{
                Loader,
                ErrorsBag,

                materials: [],
                productionTask: null,
                productionTaskId: null,
            };
        },
        methods: {
            hideModal() {
                this.$refs.CheckCreatingProductionActModal.hide();
                this.clearModal();
            },
            initModal( taskId ) {
                this.clearModal();

                if ( !taskId ) {
                    return null;
                }

                this.productionTaskId = taskId;
                this.$refs.CheckCreatingProductionActModal.show();

                this.fetchProductionTask();
            },
            clearModal() {
                ErrorsBag.discard();

                this.materials = [];
                this.productionTask = null;
                this.productionTaskId = null;
            },
            fetchProductionTask() {
                if ( !this.productionTaskId ) {
                    ErrorsBag.add( 'Не указан id задания' );

                    return null;
                }

                let params = { with: [ 'stuff.nomenclature.measureUnit' ] };

                this.$http
                    .get( PRODUCTION_TASKS_ENDPOINT + '/' + this.productionTaskId, { params: params } )
                    .then( response => {
                        this.productionTask = response.data.data;
                        this.materials = cloneDeep( this.productionTask.stuff );
                    }, response => ErrorsBag.fill( response.body ) );
            },
            createProductionAct() {
                if ( !this.productionTask ) {
                    ErrorsBag.add( 'Задание не определенно' );

                    return null;
                }

                this.$http
                    .post( PRODUCTION_TASKS_ENDPOINT + '/' + this.productionTask.id + '/production_acts/new' )
                    .then( response => {
                        let productionAct = response.data.data;

                        if ( productionAct !== null ) {
                            this.hideModal();
                            this.$router.push( { name: this.route.name, params: { id: productionAct.id } } );
                        }
                    }, response => ErrorsBag.fill( response.body ) );
            }
        }
    }
</script>
