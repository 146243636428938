import {
    ROLE_ADMIN,
    ROLE_STOCKMAN,
    ROLE_STORAGE_SENIOR,
    ROLE_SHIFT_SUPERVISOR,
    ROLE_STOCKMAN_SENIOR
} from "@utils/Roles";

export default [
    {
        name: 'StorageReportIndex',
        path: '/report/storage',
        component: () => import('@components/storagereport/StorageReportIndex'),
        meta: {
            label: 'Остатки',
            auth: [ROLE_ADMIN, ROLE_STORAGE_SENIOR, ROLE_STOCKMAN, ROLE_SHIFT_SUPERVISOR],
        }
    },
    {
        name: 'MovementHistory',
        path: '/storage_movement/history',
        component: () => import('@components/storagereport/MovementHistory'),
        meta: {
            label: 'История перемещений',
            auth: [ROLE_ADMIN, ROLE_SHIFT_SUPERVISOR, ROLE_STOCKMAN_SENIOR]
        }
    }
]
