import { ROLE_ADMIN, ROLE_STOCKMAN, ROLE_QUALITY_CONTROL } from "@utils/Roles";

export default [
    {
        name: 'AdmissionActList',
        path: '/admission-acts',
        component: () => import('@views/admission-acts/Index.vue'),
        meta: {
            label: 'Склад / Акты снабжения',
            auth: [ ROLE_ADMIN, ROLE_STOCKMAN, ROLE_QUALITY_CONTROL ],
            fullsize: true
        }
    },
    {
        name: 'AdmissionActCreate',
        path: '/admission-acts',
        component: () => import('@views/admission-acts/Form.vue'),
        meta: {
            label: 'Склад / Акты снабжения / Создание',
            auth: [ROLE_ADMIN, ROLE_STOCKMAN]
        }
    },
    {
        name: 'AdmissionActEdit',
        path: '/admission-acts/:id',
        component: () => import('@views/admission-acts/Form.vue'),
        props: true,
        meta: {
            label: 'Склад / Акты снабжения / Редактирование',
            auth: [ROLE_ADMIN, ROLE_STOCKMAN, ROLE_QUALITY_CONTROL],
            fullsize: true,
        }
    },
]
