<template>
    <c-sidebar
        fixed
        :minimize="minimize"
        :show="show"
        @update:show="(value) => toggleSidebarShow(value)"
        size="lg"
        class="d-print-none"
    >
        <c-sidebar-brand to="/storage-senior-dashboard" class="bg-white text-info">
            <strong>GF Производство</strong>
        </c-sidebar-brand>

        <c-sidebar-nav>
            <template v-for="(item, index) in navigation">
                <template v-if="!item.children">
                    <c-sidebar-nav-item
                        v-show="checkPermission(item.to.name)"
                        :name="item.title"
                        :font-icon="item.icon"
                        :key="index"
                        :to="{name: item.to.name}"
                        :badge="item.badge && navLinkBadgeValue(item.badge) ? {color: 'danger', text: navLinkBadgeValue(item.badge)} : {}"
                    />
                </template>
                <template v-else>
                    <c-sidebar-nav-dropdown
                        v-show="checkPermissions(item.children.map(c => c.to.name))"
                        :name="item.title"
                        :items="item.children"
                        :font-icon="item.icon"
                        :key="index"
                    >
                        <template v-for="(child, childIndex) in item.children">
                            <c-sidebar-nav-item
                                v-show="checkPermission(child.to.name)"
                                :name="child.title"
                                :font-icon="child.icon"
                                :key="childIndex"
                                :to="{name: child.to.name}"
                                :badge="child.badge && navLinkBadgeValue(child.badge) ? {color: 'danger', text: navLinkBadgeValue(child.badge)} : {}"
                            />
                        </template>
                    </c-sidebar-nav-dropdown>
                </template>
            </template>
        </c-sidebar-nav>

        <c-sidebar-minimizer @click.native="toggleSidebarMinimize"/>
    </c-sidebar>
</template>

<script>
import navigation   from '../config/navigation';
import {routes}     from '../router';
import {mapGetters} from 'vuex';
import {
    CSidebar,
    CSidebarBrand,
    CSidebarNav,
    CSidebarNavItem,
    CSidebarNavDropdown,
    CSidebarMinimizer
}                   from '@coreui/vue';

export default {
    name: 'TheSidebar',
    components: {
        CSidebar,
        CSidebarBrand,
        CSidebarNav,
        CSidebarNavItem,
        CSidebarNavDropdown,
        CSidebarMinimizer
    },
    data() {
        return {
            navigation,
        };
    },
    computed: {
        show() {
            return this.$store.state.sidebarShow && this.$auth.check();
        },
        minimize() {
            return this.$store.state.sidebarMinimize && this.$auth.check();
        },
        ...mapGetters({
            availableToReceiveCount: 'material_transfer_act/availableToReceiveCount/count',
            plannedSanitaryInspectionCount: 'sanitary_inspections/plannedCount/count',
            approvedCorrectionActCount: 'correction_acts/approvedCount/count',
            materialTransferActsCreatedByQualityControlAct: 'material_transfer_act/createdByQualityControlActCount/count',
        }),
    },
    methods: {
        navLinkBadgeValue(name) {
            return this[name]
        },
        checkPermissions(routeNames) {
            for (let i = 0; i < routeNames.length; i++) {
                if (this.checkPermission(routeNames[i])) {
                    return true
                }
            }

            return false
        },
        checkPermission(routeName) {
            let route = routes.find(r => r.name === routeName);

            if (!route || !route.meta || !route.meta.auth || route.meta.auth === true) {
                return true
            }

            return this.$auth.check(route.meta.auth)
        },
        toggleSidebarMinimize() {
            return this.$store.commit('set', ['sidebarMinimize', !this.minimize])
        },
        toggleSidebarShow() {
            return this.$store.commit('toggleSidebarDesktop');
        },
    },
}
</script>
