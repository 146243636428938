import {ROLE_ADMIN, ROLE_LABELER, ROLE_SHIFT_SUPERVISOR, ROLE_STORAGE_SENIOR} from "@utils/Roles";

export default [
    {
        name: 'Packing',
        path: '/box/lot_assignment',
        component: () => import('@components/box/Packing/PackingWrapper'),
        meta: {
            label: 'Сборка ящиков',
            auth: [ROLE_ADMIN, ROLE_STORAGE_SENIOR, ROLE_LABELER]
        }
    },
    {
        name: 'BoxWeightAssignment',
        path: '/box/weight_assignment',
        component: () => import('@components/box/WeightAssignment'),
        meta: {
            label: 'Назначение массы гастроемкостей и ящиков',
            auth: [ROLE_ADMIN, ROLE_SHIFT_SUPERVISOR]
        }
    }
]
