<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-light bg-white">
            <a class="navbar-brand" href="#" @click.prevent="$store.commit('toggleSidebarDesktop')">
                <b-icon-list scale="1.2"/>
            </a>

            <header-toolbar class="mr-auto"/>

            <b-dropdown variant="outline-dark" offset="-150">
                <template #button-content>
                    <b-icon-person-badge-fill/>
                </template>

                <b-dropdown-text>
                    <div class="font-weight-bold">
                        {{ user.fullname }}
                    </div>

                    {{ user.username }}
                </b-dropdown-text>

                <b-dropdown-divider/>

                <b-dropdown-item href="#" @click.prevent="reload">
                    <b-icon-arrow-repeat class="mr-1"/>
                    Обновить приложение
                </b-dropdown-item>

                <b-dropdown-item href="#" @click.prevent="clearLocalStorage">
                    <b-icon-exclamation-triangle class="mr-1"/>
                    Очистить кэш
                </b-dropdown-item>

                <b-dropdown-divider/>

                <b-dropdown-item href="#" @click="logout">Выйти</b-dropdown-item>

            </b-dropdown>
        </nav>
        <div v-if="!(!!$route.meta.hide_breadcrumbs)" class="nav-scroller bg-white">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <small>{{ breadcrumb }}</small>
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</template>

<script>
import {isReview} from '@utils/environment';
import HeaderToolbar from "@components/_common/HeaderToolbar";

export default {
    name: 'TheHeader',
    computed: {
        user() {
            return this.$auth.user();
        },
        breadcrumb() {
            return this.$route.meta.label ?? this.$route.meta.breadcrumb;
        }
    },
    methods: {
        isReview,
        shouldUpdate() {
            return this.$emit('shouldUpdate');
        },
        reload() {
            return this.$emit('reload');
        },
        clearLocalStorage() {
            this.$bvModal.msgBoxConfirm('Вы уверены что хотите очистить локальное хранилище?', { size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Да',
                cancelTitle: 'Нет',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then((value) => {
                if (value === true) {
                    window.localStorage.clear();
                    window.location.reload();
                }
            })
        },
        logout() {
            return this.$emit('logout')
        },
    },
    components: {
        HeaderToolbar,
    }
}
</script>
