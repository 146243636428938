<template>
    <div>
        <login-modal name="login" />
        <storage-movements-modal name="storage-movements" />
        <check-saving-production-act ref="CheckSavingProductionAct"/>
        <check-creating-production-act ref="CheckCreatingProductionAct"/>
        <check-multi-tasks-creating-production-act ref="CheckMultiTasksCreatingProductionAct"/>
        <check-closing-act v-if="$auth.check()" ref="CheckClosingAct"/>
    </div>
</template>

<script>
    import LoginModal from '../_globals/modals/LoginModal';
    import CheckClosingAct from '../_globals/modals/CheckClosingAct';
    import StorageMovementsModal from '../_globals/modals/StorageMovementsModal';
    import CheckSavingProductionAct from '../_globals/modals/CheckSavingProductionAct';
    import CheckCreatingProductionAct from '../_globals/modals/CheckCreatingProductionAct';
    import CheckMultiTasksCreatingProductionAct from '../_globals/modals/CheckMultiTasksCreatingProductionAct';

    export default {
        name: "Modals",
        components: {
            LoginModal,
            StorageMovementsModal,
            CheckMultiTasksCreatingProductionAct,
            CheckCreatingProductionAct,
            CheckSavingProductionAct,
            CheckClosingAct
        },
        methods: {
            /**
             * Получаем доступ к модальному окну по его ref
             *
             * @param name
             * @return {Vue | Element | Vue[] | Element[] | *}
             */
            getModal( name ) {
                return this.$refs[ name ];
            }
        }
    }
</script>
