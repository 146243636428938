import { ROLE_ACCOUNTANT, ROLE_STORAGE_SENIOR, ROLE_STOCKMAN_SENIOR } from '@utils/Roles';

export default [
    {
        name: 'WorkPositionsList',
        path: '/admin/work-positions',
        component: () => import('@views/admin/work-positions/WorkPositionsList.vue'),
        meta: {
            breadcrumb: 'Администрирование / Должности',
            auth: [ ROLE_ACCOUNTANT, ROLE_STORAGE_SENIOR, ROLE_STOCKMAN_SENIOR ],
            fullsize: true
        }
    },
    {
        name: 'WorkPositionsCreate',
        path: '/admin/work-positions',
        component: () => import('@views/admin/work-positions/WorkPositionsCreate.vue'),
        meta: {
            breadcrumb: 'Администрирование / Должности / Создание',
            auth: [ ROLE_ACCOUNTANT, ROLE_STORAGE_SENIOR, ROLE_STOCKMAN_SENIOR ]
        }
    },
    {
        props: true,
        name: 'WorkPositionsEdit',
        path: '/admin/work-positions/:id',
        component: () => import('@views/admin/work-positions/WorkPositionsEdit.vue'),
        meta: {
            breadcrumb: 'Администрирование / Должности / Редактирование',
            auth: [ ROLE_ACCOUNTANT, ROLE_STORAGE_SENIOR, ROLE_STOCKMAN_SENIOR ]
        }
    },
]
