import {ROLE_ADMIN, ROLE_SHIFT_SUPERVISOR, ROLE_STORAGE_SENIOR} from '@utils/Roles';

export default [
    {
        name: 'LabelingList',
        path: '/labeling',
        component: () => import('@components/labeling/LabelingList'),
        meta: {
            label: 'Этикетирование',
            auth: [ ROLE_ADMIN, ROLE_STORAGE_SENIOR, ROLE_SHIFT_SUPERVISOR ],
            fullsize: true
        }
    },
    {
        name: 'LabelingList2',
        path: '/labeling-2',
        component: () => import('@components/labeling2/LabelingList'),
        meta: {
            label: 'Этикетирование',
            auth: [ ROLE_ADMIN, ROLE_STORAGE_SENIOR, ROLE_SHIFT_SUPERVISOR ],
            fullsize: true
        }
    },
    {
        name: 'LabelingRestriction',
        path: '/labeling/restrictions',
        component: () => import('@components/labeling2/LabelingRestriction'),
        meta: {
            label: 'Ограничения этикетировки',
            auth: [ROLE_ADMIN]
        }
    }
];
