<template>
    <div>
        <div slot="modal-header" class="w-100">
            <b-row align-h="center">
                <h3 :style="style">Загружаю...</h3>
            </b-row>
        </div>
        <b-row align-h="center">
            <b-spinner variant="primary"></b-spinner>
        </b-row>
    </div>
</template>

<script>
    import clone from 'lodash/clone';

    const DEFAULT_SIZE = '70px';
    const DEFAULT_HEX_COLOR = '#4dd2ff';

    export default {
        name: "ModalSpinner",
        props: {
            size: { type: String, default: DEFAULT_SIZE },
            color: { type: String, default: DEFAULT_HEX_COLOR }
        },
        data() {
            return {
                hexCode: clone( this.color ),
                spinnerSize: clone( this.size )
            };
        },
        computed: {
            style() {
                return 'color:' + this.hexCode + ';font-size:' + this.spinnerSize + 'px;';
            }
        }
    }
</script>
