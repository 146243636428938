<template>
    <b-modal
        @hidden='hideModal'
        title="Сохранение акта выпуска"
        ref='CheckSavingProductionActModal'
        id='check-saving-production-act-modal'
    >
        <modal-error v-if="ErrorsBag.state()"></modal-error>
        <modal-spinner v-else-if="Loader.state()"></modal-spinner>

        <missing-raw-materials
            @goTo="hideModal"
            v-model="materials"
            :storage-id="storageId"
            v-show="!Loader.state() && !ErrorsBag.state()"
        ></missing-raw-materials>

        <template #modal-footer>
            <div class="w-100">
                <b-row align-h="around">
                    <b-button variant="default" size="lg" @click="hideModal" v-if="!ErrorsBag.state()">Закрыть</b-button>
                    <b-button variant="success" size="lg" @click="saveProductionAct" v-if="actionAccessible">
                        Сохранить
                    </b-button>
                </b-row>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import find from 'lodash/find';
    import forEach from 'lodash/forEach';
    import cloneDeep from 'lodash/cloneDeep';

    import Loader from '../../../utils/loader';
    import ErrorsBag from '../../../utils/errorsBag';

    import ProductionActRoutes from '../../../router/production-act';
    import { PRODUCTION_ACTS_ENDPOINT, PRODUCTION_TASKS_ENDPOINT } from '../../../utils/endpoints';

    import ModalError from '../../_common/ModalError';
    import ModalSpinner from '../../_common/ModalSpinner';

    import MissingRawMaterials from './bodies/MissingRawMaterials';

    export default {
        name: "CheckSavingProductionAct",
        components: { ModalError, ModalSpinner, MissingRawMaterials },
        computed: {
            route() {
                return find( ProductionActRoutes, route => route.name === 'ProductionActUpdate' );
            },
            storageId() {
                return this.productionAct ? this.productionAct.storage.id : null;
            },
            actionAccessible() {
                return !Loader.state() && !ErrorsBag.state() && !!this.productionAct;
            },
        },
        data() {
            return {
                Loader,
                ErrorsBag,

                materials: [],
                currentActId: null,
                productionAct: null,
            };
        },
        methods: {
            hideModal() {
                this.$refs.CheckSavingProductionActModal.hide();
                this.clearModal();
            },
            initModal( productionAct, currentActId ) {
                this.clearModal();

                if ( !productionAct ) {
                    return null;
                }

                this.currentActId = currentActId;
                this.productionAct = productionAct;

                if ( this.productionAct.use_proportional_raw_materials ) {
                    forEach( this.productionAct.tasks, task => this.fetchProductionTask( task.id ) );
                } else {
                    this.materials = cloneDeep( this.productionAct.rawMaterials );
                }

                this.$refs.CheckSavingProductionActModal.show();
            },
            clearModal() {
                ErrorsBag.discard();

                this.materials = [];
                this.currentActId = null;
                this.productionAct = null;
            },
            fetchProductionTask( taskId ) {
                let params = { with: [ 'stuff.nomenclature.measureUnit' ] };

                this.$http
                    .get( PRODUCTION_TASKS_ENDPOINT + '/' + taskId, { params: params } )
                    .then(
                        response => forEach( response.data.data.stuff, stuff => this.materials.push( stuff ) ),
                        response => ErrorsBag.fill( response.body )
                    );
            },
            saveProductionAct() {
                let method = this.currentActId ? 'put' : 'post';
                let url = PRODUCTION_ACTS_ENDPOINT + ( this.currentActId ? `/${this.currentActId}` : '' );

                this.$http[ method ]( url, this.productionAct )
                    .then( response => {
                        this.$emit( 'callback', response.data );
                        this.hideModal();
                    } )
                    .catch( response => ErrorsBag.fill( response.body ) );
            }
        }
    }
</script>
