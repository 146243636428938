'use strict';

import {PRODUCTION_ACTS_ENDPOINT} from '@utils/endpoints.js';
import HttpClient                 from './http-client';

class ProductionActService {
    constructor() {
        this.uri = PRODUCTION_ACTS_ENDPOINT;
    }

    async getMaterialTransferInvoice(id) {
        const response = await HttpClient.get(
            `${this.uri}/${id}/material-transfer-invoice`,
            {
                responseType: 'arraybuffer',
            }
        );

        return response.data;
    }
}

export default new ProductionActService();
