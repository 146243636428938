<template>
    <modal
        id="storage-movements-modal"
        name="storage-movements"
        @before-open="beforeOpen"
        height="auto"
    >
        <div v-if="loading" class="alert alert-info text-center" style="margin-bottom: 0;">
            <i class="fas fa-spinner fa-spin" /> Загрузка...
        </div>
        <table v-else class="table table-sm table-striped" style="margin-bottom: 0;">
            <thead class="thead-dark">
                <tr>
                    <th>#</th>
                    <th>Номенклатура</th>
                    <th class="text-center">Партия</th>
                    <th class="text-center">Кол-во</th>
                    <th class="text-center">Акт</th>
                    <th class="text-center">Цех</th>
                    <th class="text-center">Дата</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in storageMovements" :key="item.id">
                    <td>{{ item.id }}</td>
                    <td>{{ item.nomenclature.name }}</td>
                    <td class="text-center">{{ item.nomenclatureLot ? item.nomenclatureLot.name : '---' }}</td>
                    <td class="text-center">
                        <b>{{ parseFloat(item.count).toFixed(3) }}</b>
                    </td>
                    <td class="text-center">
                        <router-link
                            v-if="getLink(item) !== null"
                            target="_blank"
                            :to="getLink(item)"
                        >[{{item.act_id}}] {{item.actType.name}}</router-link>
                        <span v-else>[{{ item.act_id }}] {{ item.actType.name }}</span>
                    </td>
                    <td class="text-center">{{ item.storage.name }}</td>
                    <td class="text-center">{{ moment(item.date).format('DD.MM.YYYY HH:mm:ss') }}</td>
                </tr>
            </tbody>
        </table>
        <div
            v-show="!loading && !storageMovements.length"
            class="alert alert-light text-center"
            style="margin-bottom: 0;"
        >Список перемещений сырья пуст</div>
    </modal>
</template>

<script>
    import moment from "moment"
    import { STORAGE_MOVEMENT_ENDPOINT } from "../../../utils/endpoints"

    export default {
        name: "StorageMovementsModal",
        props: ["nomenclature", "storage"],
        data() {
            return {
                moment,
                loading: false,
                storageMovements: []
            }
        },
        methods: {
            getLink(item) {
                let name = null
                switch (item.actType.id) {
                    case 1:
                        name = "AdmissionActEdit"
                        break
                    case 3:
                        name = "MaterialTransferActUpdate"
                        break
                    case 4:
                        name = "ProductionActUpdate"
                        break
                    case 5:
                        name = "ShiftClosingWizard"
                        break
                    case 6:
                        name = "OrderToContractorActForm"
                        break
                    case 7:
                        name = "QualityControlActUpdate"
                        break
                    case 8:
                        name = "InventoryActUpdate"
                        break
                }

                if (name !== null) {
                    return {
                        name: name,
                        params: {
                            id: item.act_id
                        }
                    }
                }

                return null
            },
            beforeOpen({ params: { storage, actualShift, nomenclature } }) {
                this.loading = true
                this.storageMovements = []
                this.$http
                    .get(STORAGE_MOVEMENT_ENDPOINT, {
                        params: {
                            filters: {
                                withTrashed: true,
                                nomenclatureId: nomenclature.id,
                                ...(storage && { storageIds: [storage.id] }),
                                ...(actualShift && { actualShiftId: actualShift.id })
                            },
                            with: [
                                "actType", //
                                "nomenclature",
                                "nomenclatureLot",
                                "storage"
                            ],
                            sort: "date",
                            sortDirection: "desc",
                            without_loading: true,
                            limit: 1000
                        }
                    })
                    .then(response => response.data.data.data)
                    .then(storageMovements => {
                        this.storageMovements = storageMovements
                    })
                    .catch(error => {
                        console.error(error)
                        this.$toast.console.error("Не удалось загрузить список перемещений сырья!")
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        }
    }
</script>

<style>
    #storage-movements-modal {
        z-index: 9999;
        background: rgba(0, 0, 0, 0.4);
    }
    #storage-movements-modal .v--modal {
        left: auto !important;
        width: 100% !important;
        max-width: 95% !important;
        max-height: 60vh !important;
        overflow-y: auto !important;
        margin: 30px auto !important;
    }
</style>
