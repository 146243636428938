<template>
    <select class="form-control" @change="onChange" v-model="internalValue" :disabled="disabled">
        <option :value="null">Выберите смену</option>
        <option v-for="shift in shifts" :value="shift.id">
            {{ shift.name }}
        </option>
    </select>
</template>

<script>
    import {PRODUCTION_SHIFT_ENDPOINT} from "../../utils/endpoints";

    export default {
        props: {
            value: {},
            disabled: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            value() {
                this.internalValue = this.value ? this.value.id : null
            }
        },
        data() {
            return {
                internalValue: this.value ? this.value.id : null,
                shifts: []
            }
        },
        methods: {
            load() {
                this.$http.get(PRODUCTION_SHIFT_ENDPOINT + '/list')
                    .then(response => {
                        this.shifts = response.data;
                    }).catch(response => this.$root.responseError(response, 'Ошибка загрузки смен'));
            },
            onChange() {
                this.$emit('input', this.shifts.find(shift => shift.id === this.internalValue))
            },
        },
        mounted() {
            this.load();
        }
    }
</script>
