import Vue from 'vue';
import { BARCODES_ENDPOINT } from '../../utils/endpoints';

const _PAD = '*';
const _DELIMITER = '#';
const _PREFIX_LENGTH = 10;

const _ERROR_PREFIX = 'BarcoderError: ';

const PNG_TYPE = 'png';
const JPG_TYPE = 'jpg';
const SVG_TYPE = 'svg';

export { PNG_TYPE, JPG_TYPE, SVG_TYPE };

/**
 * Класс генерации и парсинга ШК переходов
 */
class Barcoder {
    /**
     * Парсинг ШК
     *
     * @param {string} barcode
     * @return {{prefix: *, parameters: *}}
     */
    static parse( barcode ) {
        try {
            let regexp = new RegExp( '\\' + _PAD, 'g' );
            let prefix = barcode.substr( 0, _PREFIX_LENGTH ).replace( regexp, '' );
            let parameters = JSON.parse( barcode.substr( _PREFIX_LENGTH + 1 ) );

            return { prefix: prefix, parameters: parameters };
        } catch ( e ) { /* не обрабатываем. данный метод обязан возвращать объект */ }

        return { prefix: null, parameters: null };
    }

    /**
     * Получаем изображения по ШК
     *
     * @param barcodes
     * @param type
     * @return {*}
     */
    static get( barcodes, type = PNG_TYPE ) {
        let params = { barcodes: barcodes };

        return Vue.http.post( BARCODES_ENDPOINT + '/generate/' + type, params );
    }

    /**
     * Генерация ШК по префиксу и параметрам
     *
     * @param {string} prefix
     * @param {object} parameters
     * @return {string}
     */
    static generate( prefix, parameters = {} ) {
        if ( typeof prefix !== 'string' || !prefix ) {
            throw new Error( _ERROR_PREFIX + ' prefix required and must be string' );
        }

        return prefix.padStart( _PREFIX_LENGTH, _PAD ) + _DELIMITER + JSON.stringify( parameters );
    }
}

export default Barcoder;
