import { ROLE_ADMIN, ROLE_DIRECTOR } from '@utils/Roles';

export default [
    {
        props: true,
        name: 'SpecificationsDashboard',
        path: '/specifications/dashboard/:nomenclature_id?',
        component: () => import('@components/specifications/SpecificationsDashboard'),
        meta: {
            label: 'Спецификации',
            auth: [ ROLE_ADMIN, ROLE_DIRECTOR ],
            fullsize: true
        }
    }
]
