import { render, staticRenderFns } from "./HeaderToolbar.vue?vue&type=template&id=50b049ec&scoped=true&"
import script from "./HeaderToolbar.vue?vue&type=script&lang=js&"
export * from "./HeaderToolbar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50b049ec",
  null
  
)

export default component.exports