import { ROLE_ACCOUNTANT, CODE_ITO_HEAD, CODE_AHO_HEAD, ROLE_STORAGE_SENIOR, ROLE_STOCKMAN_SENIOR } from '@utils/Roles';

export default [
    {
        name: 'UsersList',
        path: '/users',
        component: () => import('@components/users/UsersList'),
        meta: {
            breadcrumb: 'Администрирование / Пользователи',
            auth: [ ROLE_ACCOUNTANT, ROLE_STORAGE_SENIOR, ROLE_STOCKMAN_SENIOR, CODE_ITO_HEAD, CODE_AHO_HEAD ],
            fullsize: true
        }
    },
    {
        name: 'UsersCreate',
        path: '/users',
        component: () => import('@components/users/UsersCreate'),
        meta: {
            breadcrumb: 'Администрирование / Пользователи / Создание',
            auth: [ ROLE_ACCOUNTANT, ROLE_STORAGE_SENIOR, ROLE_STOCKMAN_SENIOR, CODE_ITO_HEAD, CODE_AHO_HEAD ]
        }
    },
    {
        props: true,
        name: 'UsersEdit',
        path: '/users/:id',
        component: () => import('@components/users/UsersEdit'),
        meta: {
            breadcrumb: 'Администрирование / Пользователи / Редактирование',
            auth: [ ROLE_ACCOUNTANT, ROLE_STORAGE_SENIOR, ROLE_STOCKMAN_SENIOR, CODE_ITO_HEAD, CODE_AHO_HEAD ]
        }
    },
    {
        props: true,
        name: 'UsersBarcode',
        path: '/users/:id/barcode',
        component: () => import('@components/users/UsersBarcode'),
        meta: {
            breadcrumb: 'Администрирование / Пользователи / ШК пользователя',
            auth: true
        }
    },
    {
        name: 'UsersProfile',
        path: '/welcome',
        component: () => import('@components/users/UsersProfile'),
        meta: {
            breadcrumb: 'Администрирование / Пользователи / Профиль',
            auth: true,
            fullsize: true
        }
    }
]
