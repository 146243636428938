import {ROLE_ADMIN, ROLE_STORAGE_SENIOR, ROLE_SHIFT_SUPERVISOR} from "@utils/Roles";

export default [
    {
        name: 'ProductionActCreate',
        path: '/production-acts',
        component: () => import('@components/productionact/ProductionActForm'),
        meta: {
            breadcrumb: 'Акты выпуска сырья / Создание',
            auth: [ROLE_ADMIN, ROLE_STORAGE_SENIOR]
        }
    },
    {
        name: 'ProductionActUpdate',
        path: '/production-acts/:id',
        component: () => import('@components/productionact/ProductionActForm'),
        props: true,
        meta: {
            breadcrumb: 'Акты выпуска сырья / Просмотр',
            auth: [ROLE_ADMIN, ROLE_STORAGE_SENIOR, ROLE_SHIFT_SUPERVISOR]
        }
    },
]
