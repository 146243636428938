'use strict';

import plannedCount from './plannedCount'

export default {
    namespaced: true,
    modules: {
        plannedCount
    }
}
