export default [
    {
        name: 'Login',
        path: '/login',
        component: () => import('@components/Login'),
        meta: {
            label: 'Вход'
        }
    }
]
