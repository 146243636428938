'use strict';

import approvedCount from './approvedCount'

export default {
    namespaced: true,
    modules: {
        approvedCount
    }
}
