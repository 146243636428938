import {
    MATERIAL_TRANSFER_ACT_ENDPOINT,
    PRODUCTION_TASKS_ENDPOINT,
    SHIFT_CLOSING_ACT_ENDPOINT,
    STORAGE_ENDPOINT
} from '../../../utils/endpoints';
import {$app as App} from '../../../main';
import ErrorHandler from "../../../components/productiontask/StorageSeniorDashboard/ErrorHandler";
import {findIndex} from 'lodash';

export default {
    namespaced: true,
    state: {
        storage: null,
        actualShift: null,
        selectedTasks: [],
        rawStuffData: null,

        tasksBeingLoaded: false,
        tasks: [],
        plans: [],
        filters: {
            planNomenclatureName: '',
            taskNomenclatureName: '',
            plans: [],
        },

        restsBeingLoaded: false,
        rests: {},

        notReceivedTransfersBeingLoaded: false,
        notReceivedTransfers: [],
        transferToReceive: null,

        notTransferredProducedLotsBeingLoaded: false,
        notTransferredProducedLots: [],

        shiftClosingAct: null,
    },
    mutations: {
        set_storage(state, value) {
            state.storage = value;
        },
        set_actual_shift(state, value) {
            state.actualShift = value;
        },
        set_raw_stuff_data(state, value) {
            state.rawStuffData = value;
        },
        set_tasks_being_loaded(state, value) {
            state.tasksBeingLoaded = value;
        },
        set_tasks(state, value) {
            state.tasks = value;
        },
        set_plans(state, value) {
            state.plans = value;
        },
        toggle_plan_filter(state, {id, value}) {
            let index = findIndex(state.filters.plans, i => i===id);
            if (index !== -1) {
                state.filters.plans.splice(index, 1);
            }

            if (value) {
                state.filters.plans.push(id);
            }
        },
        set_plan_filter(state, value) {
            state.filters.plans = value;
        },
        set_selected_tasks(state, value) {
            state.selectedTasks = value;
        },
        set_not_received_transfers(state, value) {
            state.notReceivedTransfers = value;
        },
        set_transfer_to_receive(state, value) {
            state.transferToReceive = value;
        },
        set_not_received_transfers_being_loaded(state, value) {
            state.notReceivedTransfersBeingLoaded = value;
        },
        set_not_transferred_produced_lots_being_loaded(state, value) {
            state.notTransferredProducedLotsBeingLoaded = value;
        },
        set_not_transferred_produced_lots(state, value) {
            state.notTransferredProducedLots = value;
        },
        set_rests(state, value) {
            state.rests = value;
        },
        set_rests_being_loaded(state, value) {
            state.restsBeingLoaded = value;
        },
        set_shift_closing_act(state, value) {
            state.shiftClosingAct = value;
        }
    },
    actions: {
        updateSelectedTask({state, commit}, task) {
            let tasks = state.selectedTasks;
            tasks.map(t => {
                if (t.key === task.key) {
                    t = task;
                }
            });
            commit('set_selected_tasks', tasks);
        },
        updateTask({state, commit}, task) {
            let tasks = state.tasks;
            tasks.map(t => {
                if (t.key === task.key) {
                    t = task;
                }
            });
            commit('set_tasks', tasks);
        },
        loadNotReceivedTransfers({state, commit}) {
            if (!state.storage) {
                return;
            }
            if (state.notReceivedTransfersBeingLoaded) {
                return;
            }
            commit('set_not_received_transfers_being_loaded', true);
            App.$http.get(MATERIAL_TRANSFER_ACT_ENDPOINT + '/unreceived', {
                params: {
                    storage: {
                        id: state.storage.id
                    },
                    with: [
                        'shift.shift',
                        'storageFrom',
                        'storageTo',
                        'author',
                        'receivedBy',
                        'items.nomenclatureLot.nomenclature.measureUnit'
                    ],
                    without_loading: true,
                }
            }).then(response => {
                commit('set_not_received_transfers', response.data.data);
            }).catch(response => {
                ErrorHandler.assert(response,  'Ошибка получения непринятых передач');
                commit('set_not_received_transfers', []);
            }).finally(() => {
                commit('set_not_received_transfers_being_loaded', false);
            });
        },
        loadNotTransferredProducedLots({state, commit}) {
            if (!state.storage || !state.actualShift) {
                return;
            }
            if (state.notTransferredProducedLotsBeingLoaded) {
                return;
            }
            commit('set_not_transferred_produced_lots_being_loaded', true);
            App.$http.get(STORAGE_ENDPOINT + '/produced-not-transferred-lots', {
                params: {
                    without_loading: true,

                    storage_id: state.storage.id,
                    actual_shift_id: state.actualShift.id,
                }
            }).then(response => {
                commit('set_not_transferred_produced_lots', response.data);
            }).catch(response => {
                ErrorHandler.assert(response, 'Ошибка получения непереданных партий');
                commit('set_not_transferred_produced_lots', []);
            }).finally(() => {
                commit('set_not_transferred_produced_lots_being_loaded', false);
            });
        },
        loadRests({state, commit}) {
            if (!state.storage) {
                return;
            }
            if (state.restsBeingLoaded) {
                return;
            }
            commit('set_rests_being_loaded', true);
            App.$http.get(STORAGE_ENDPOINT + '/report', {
                params: {
                    filters: {
                        storageIds: [state.storage.id]
                    },
                    groupByLots: 0,
                    keyBy: 'nomenclature_id',

                    without_loading: true,
                }
            }).then(response => {
                commit('set_rests', response.data);
            }).catch(response => {
                ErrorHandler.assert(response, 'Ошибка получения остатков');
                commit('set_rests', []);
            }).finally(() => {
                commit('set_rests_being_loaded', false);
            });
        },
        loadTasks({state, commit}) {
            if (!state.storage || !state.actualShift) {
                return;
            }
            if (state.tasksBeingLoaded) {
                return;
            }
            commit('set_tasks_being_loaded', true);
            App.$http.get(PRODUCTION_TASKS_ENDPOINT + '/dashboard', {
                params: {
                    actual_shift_id: state.actualShift.id,
                    storage_id: state.storage.id,
                    with_before_last_shift_tasks: true,

                    without_loading: true
                }
            }).then(response => {
                commit('set_tasks', response.data.tasks.map((task) => {
                    return {...task, selected: false}
                }));
                commit('set_plans', response.data.plans);
            }).catch(response => {
                ErrorHandler.assert(response, 'Ошибка получения списка задач');
                commit('set_tasks', []);
            }).finally(() => {
                commit('set_tasks_being_loaded', false);
            });
        },
        reCalculateRawMaterialsStatus({state, commit}) {
            if (!state.tasks.length || Object.keys(!state.rests).length) {
                return;
            }
            let tasks = state.tasks;
            tasks.map(t => {
                t.stuff.map(s => {
                    s.count = parseFloat(s.count);
                    s.on_stock = parseFloat(state.rests[s.nomenclature.id] ? state.rests[s.nomenclature.id].count : 0)
                });
            });

            commit('set_tasks', tasks);
        },
        loadShiftClosingAct({state, commit}) {
            App.$http.get(SHIFT_CLOSING_ACT_ENDPOINT + `/of-actual-shift/${state.actualShift.id}/${state.storage.id}`, {
                params: {
                    without_loading: false,
                }
            }).then(response => {
                commit('set_shift_closing_act', !!response.data ? response.data : null);
            }).catch(response => {
                if (response.status !== 0 && response.status !== 404) {
                    ErrorHandler.assert(response, 'Ошибка получения акта закрытия смены');
                }
                commit('set_shift_closing_act', null);
            });
        },
        reload({commit, dispatch}) {
            commit('set_selected_tasks', []);
            dispatch('loadShiftClosingAct');
            dispatch('loadTasks');
            dispatch('loadNotReceivedTransfers');
            dispatch('loadRests');
        }
    }
}
