<template>
    <div></div>
</template>

<script>
    export default {
        name: "Sounds",
        methods: {
            windowsError() {
                let audio = new Audio('/static/audio/errors/windows-error.mp3');
                audio.play();
            },
        },
        mounted() {
            document.addEventListener('SoundErrorOccurred', evt => {
                this.windowsError();
            });
        }
    }
</script>

<style scoped>

</style>
