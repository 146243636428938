import { ROLE_ADMIN } from "@utils/Roles";

export default [
    {
        name: 'Areas',
        path: '/areas',
        component: () => import('@views/areas/Index'),
        meta: {
            breadcrumb: 'Еще / Площадки',
            auth: [ ROLE_ADMIN],
        }
    },
    {
        name: 'AreaEdit',
        path: '/areas/:id',
        props: (route) => ({ id: Number(route.params.id) }),
        component: () => import('@views/areas/Form'),
        meta: {
            breadcrumb: 'Еще / Площадки / Редактирование',
            auth: [ROLE_ADMIN]
        }
    },
]
