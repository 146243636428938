const ROLE_ACCOUNTANT = 'ROLE_ACCOUNTANT';
const ROLE_ADMIN = 'ROLE_ADMIN';
const ROLE_COOK = 'ROLE_COOK';
const ROLE_DIRECTOR = 'ROLE_DIRECTOR';
const ROLE_GROUP_SENIOR = 'ROLE_GROUP_SENIOR';
const ROLE_PLANNER = 'ROLE_PLANNER';
const ROLE_QUALITY_CONTROL = 'ROLE_QUALITY_CONTROL';
const ROLE_STOCKMAN = 'ROLE_STOCKMAN';
const ROLE_STOCKMAN_SENIOR = 'ROLE_STOCKMAN_SENIOR';
const ROLE_STORAGE_SENIOR = 'ROLE_STORAGE_SENIOR';
const ROLE_SHIFT_SUPERVISOR = 'ROLE_SHIFT_SUPERVISOR';
const CODE_AHO_HEAD = 'CODE_AHO_HEAD';
const CODE_ITO_HEAD = 'CODE_ITO_HEAD';
const CODE_AHO_EMPLOYEE = 'CODE_AHO_EMPLOYEE';
const CODE_ITO_EMPLOYEE = 'CODE_ITO_EMPLOYEE';
const ROLE_LABELER = 'ROLE_LABELER';

const roles = [
    ROLE_ACCOUNTANT,
    ROLE_ADMIN,
    ROLE_COOK,
    ROLE_DIRECTOR,
    ROLE_GROUP_SENIOR,
    ROLE_PLANNER,
    ROLE_QUALITY_CONTROL,
    ROLE_STOCKMAN,
    ROLE_STOCKMAN_SENIOR,
    ROLE_STORAGE_SENIOR,
    ROLE_SHIFT_SUPERVISOR,
    CODE_AHO_HEAD,
    CODE_ITO_HEAD,
    CODE_AHO_EMPLOYEE,
    CODE_ITO_EMPLOYEE,
    ROLE_LABELER,
];

export {
    roles,
    ROLE_ACCOUNTANT,
    ROLE_ADMIN,
    ROLE_COOK,
    ROLE_DIRECTOR,
    ROLE_GROUP_SENIOR,
    ROLE_PLANNER,
    ROLE_QUALITY_CONTROL,
    ROLE_STOCKMAN,
    ROLE_STOCKMAN_SENIOR,
    ROLE_STORAGE_SENIOR,
    ROLE_SHIFT_SUPERVISOR,
    CODE_AHO_HEAD,
    CODE_ITO_HEAD,
    CODE_AHO_EMPLOYEE,
    CODE_ITO_EMPLOYEE,
    ROLE_LABELER,
};
