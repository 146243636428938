// Add the modules in the store
import nomenclature from './modules/nomenclature/';
import materialtransferact from './modules/materialtransferact/';
import sanitary_inspections from './modules/sanitary-inspections';
import correction_acts from './modules/correction-acts';
import kpi_statuses from './modules/salary/settings/kpi_statuses';

import storage_senior_dashboard from './modules/production-tasks/storage-senior-dashboard';


import { $app as App } from '../main';

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use( Vuex )

export default new Vuex.Store( {
    state: {
        header_toolbar: '',
        app_version: null,
        latest_app_version: null,
        sidebarShow: 'responsive',
        sidebarMinimize: false,
    },
    mutations: {
        set_header_toolbar( state, code ) {
            state.header_toolbar = code;
        },
        set_empty_toolbar( state ) {
            state.header_toolbar = '';
        },
        set_app_version( state, value ) {
            state.app_version = value;
        },
        set_latest_app_version( state, value ) {
            state.latest_app_version = value;
        },
        toggleSidebarDesktop (state) {
            const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
            state.sidebarShow = sidebarOpened ? false : 'responsive'
        },
        set (state, [variable, value]) {
            state[variable] = value
        }
    },
    // ...
    modules: {
        // todo убрать после тестов переезда с симфони
        nomenclature,

        storage_senior_dashboard: storage_senior_dashboard,
        kpi_statuses: kpi_statuses,

        sanitary_inspections: sanitary_inspections,
        correction_acts: correction_acts,
        material_transfer_act: materialtransferact,
    },
    actions: {
        get_latest_app_version( { commit } ) {
            App.$http.get( '/package.json', {
                params: {
                    without_loading: true
                }
            } ).then( response => {
                if ( !!response.data && !!response.data.version ) {
                    commit( 'set_latest_app_version', response.data.version );
                }
            } ).catch( response => {
            } );
        }
    }
} );
