<template />

<script>
    import moment from "moment";

    const LOCAL_STORAGE_KEY = 'LS_NOTIFICATION_CheckClosingAct';
    const EXPIRATION_TIME_IN_MINUTES = 10;
    const UPDATE_TIME_IN_MILLISECONDS = 60 * 1000; // every minute

    export default {
        name: "CheckClosingAct",
        mounted() {
            this.removeAllNotClosedNotifications();
            this.loadAllRemoteNotifications();
            setInterval(() => {
                this.removeAllExpiredNotifications();
                this.loadAllRemoteNotifications()
            }, UPDATE_TIME_IN_MILLISECONDS);
        },
        methods: {
            loadAllRemoteNotifications() {
                this.$http
                    .get("v2/production_shift/check-closing-act", {
                        params: {
                            without_loading: true
                        }
                    })
                    .then(response => response.data.data)
                    .then(this.onRemoteNotificationsLoaded);
            },
            onRemoteNotificationsLoaded(notifications) {
                notifications.forEach(notification => {
                    if (!this.getLocalNotification(notification)) {
                        notification.notifiedAt = moment();
                        notification.closedAt = null;
                        this.setLocalNotification(notification);
                        this.showNotification(notification);
                    }
                })
            },
            getAllLocalNotifications() {
                const item = localStorage.getItem(LOCAL_STORAGE_KEY);
                try {
                    return item ? JSON.parse(item) : {};
                } catch (e) {
                    return {};
                }
            },
            setAllLocalNotifications(notifications) {
                localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(notifications));
            },
            getLocalNotification(notification) {
                const notifications = this.getAllLocalNotifications();
                if (notification.key in notifications) {
                    return notifications[notification.key];
                }
                return null;
            },
            setLocalNotification(notification) {
                const notifications = this.getAllLocalNotifications();
                notifications[notification.key] = notification;
                this.setAllLocalNotifications(notifications);
            },
            removeAllExpiredNotifications() {
                const notifications = this.getAllLocalNotifications();
                for (let key in notifications) {
                    if (notifications.hasOwnProperty(key)) {
                        const notification = notifications[key];
                        if (notification.closedAt) {
                            const closedAt = moment(notification.closedAt);
                            const minutes = moment().diff(closedAt, 'minutes');
                            if (minutes > EXPIRATION_TIME_IN_MINUTES) {
                                delete notifications[key];
                            }
                        }
                    }
                }
                this.setAllLocalNotifications(notifications);
            },
            removeAllNotClosedNotifications() {
                const notifications = this.getAllLocalNotifications();
                for (let key in notifications) {
                    if (notifications.hasOwnProperty(key)) {
                        const notification = notifications[key];
                        if (!notification.closedAt) {
                            delete notifications[key];
                        }
                    }
                }
                this.setAllLocalNotifications(notifications);
            },
            showNotification(notification) {
                const { key, storage, actualShift, message } = notification;
                // Повторно не показываем уведомления в режиме разработки
                if (
                    Array.from(document.querySelectorAll('[data-notification-key]'))
                        .map(element => element.getAttribute('data-notification-key'))
                        .includes(key)
                ) {
                    return;
                }
                this.$toast.warning(
                    message,
                    `Закройте смену ${actualShift.name} ${storage.name}`, //
                    {
                        timeout: false,
                        buttons: [
                            [
                                `<button data-notification-key="${key}" class="btn btn-warning">Закрыть</button>`,
                                (instance, toast) => {
                                    this.goToShiftClosingWizard(notification)
                                }
                            ]
                        ],
                        onClosed: (instance, toast) => {
                            this.onNotificationClosed(notification);
                        }
                    }
                )
            },
            onNotificationClosed(notification) {
                notification.closedAt = moment();
                this.setLocalNotification(notification);
            },
            goToShiftClosingWizard({ storage, actualShift, shiftClosingAct }) {
                if (shiftClosingAct) {
                    this.$router.push({
                        name: "ShiftClosingWizard",
                        params: {
                            id: shiftClosingAct.id
                        }
                    })
                } else {
                    this.$router.push({
                        name: "ShiftClosingWizard",
                        params: {
                            id: null,
                            storage,
                            actualShift
                        }
                    })
                }
            }
        }
    }
</script>

<style scoped />
