import {ROLE_QUALITY_CONTROL, ROLE_STORAGE_SENIOR} from '@utils/Roles';

export default [
    {
        name: 'TemperatureAfterHotStorageReports',
        path: '/reports/temperature-after-hot-storage',
        component: () => import('@views/reports/temperature-after-hot-storage/Index.vue'),
        props: true,
        meta: {
            label: 'Отчетность / Отчет по температуре при готовке',
            fullsize: true,
            auth: [ROLE_STORAGE_SENIOR],
        }
    },
    {
        name: 'TemperatureAfterShockReports',
        path: '/reports/temperature-after-shock',
        component: () => import('@views/reports/temperature-after-shock/Index.vue'),
        props: true,
        meta: {
            label: 'Отчетность / Отчет по температуре п/ф в ГЦ, после шока',
            fullsize: true,
            auth: [ROLE_STORAGE_SENIOR],
        }
    },
    {
        name: 'SanitaryInspectionReports',
        path: '/reports/sanitary-inspection',
        component: () => import('@views/reports/sanitary-inspection/Index.vue'),
        meta: {
            label: 'Отчетность / Отчет по проверкам санитарных норм',
            auth: [ROLE_QUALITY_CONTROL],
            fullsize: true
        }
    },
];
