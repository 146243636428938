<template>
    <div>
        <modals ref="modals"></modals>
        <sounds ref="sounds"></sounds>
    </div>
</template>

<script>
    import Modals from './_globals/Modals';
    import Sounds from "./_globals/Sounds";

    export default {
        name: "Globals",
        components: {
            Modals,
            Sounds
        }
    }
</script>
