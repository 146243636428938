'use strict';

import BearerDriver from '@websanova/vue-auth/drivers/auth/bearer';
import RouterDriver from '@websanova/vue-auth/drivers/router/vue-router.2.x';
import ResourceDriver from '@websanova/vue-auth/drivers/http/vue-resource.1.x';

export default {
    auth: {
        request: BearerDriver.request,
        response: (response) => {
            if (response.body && response.body.access_token) {
                return response.body.access_token;
            }
        }
    },
    http: ResourceDriver,
    router: RouterDriver,
    rolesVar: 'roles',
    logoutData: {
        url: 'logout',
        redirect: '/login',
        makeRequest: false,
        success() {
            location.reload();
        }
    },
    fetchData: {url: 'v2/user'},
    refreshData: {enabled: false},
    loginData: {url: 'v2/login', redirect: 'welcome'},
    authRedirect: {path: 'login'},
    forbiddenRedirect: {path: '403'},
};
