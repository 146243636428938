'use strict';

import {PRODUCTION_TASKS_ENDPOINT} from '@utils/endpoints.js';
import HttpClient                  from './http-client';

class ProductionTaskService {
    constructor() {
        this.uri = PRODUCTION_TASKS_ENDPOINT;
    }

    async getGanttData(params) {
        const response = await HttpClient.get(`${this.uri}/list/gantt`, {params});

        return {
            data: response.data.data.tasks,
            startedAt: response.data.data.rangeStart,
            endedAt: response.data.data.rangeEnd,
        };
    }
}

export default new ProductionTaskService();
