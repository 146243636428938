import Vue from 'vue';

import find from 'lodash/find';
import forEach from 'lodash/forEach';

import Router from './Router';
import Barcoder from './Barcoder';
import Transition from './Transition';
import TRANSITIONS_LIST from './transitions/index';

const _ERROR_PREFIX = 'TransitionHandlerError: ';

/**
 * Обработчик ШК для переходов
 */
class TransitionHandler {
    /**
     * Переход по ШК
     *
     * @param {string} barcode
     * @return {Promise}
     */
    static go( barcode ) {
        return new Promise( ( resolve, reject ) => {
            let data = Barcoder.parse( barcode );

            let findTransition = TransitionHandler._find( data.prefix );
            if ( typeof findTransition === 'undefined' ) {
                return resolve( Router.response( false ) );
            }

            if ( !TransitionHandler._accessor( findTransition ) ) {
                return reject( Router.forbidden() );
            }

            try {
                return resolve( findTransition.router.go( data.parameters ) );
            } catch ( error ) {
                return reject( error );
            }
        } );
    }

    /**
     * Генерация ШК
     *
     * @param {Transition} transition
     * @param {Object|{}} data
     * @return {string|null}
     */
    static generate( transition, data = {} ) {
        let findTransition = TransitionHandler._find( transition.prefix );
        if ( typeof findTransition === 'undefined' ) {
            return null;
        }

        return Barcoder.generate( findTransition.prefix, data );
    }

    /**
     * Обрабатываем ошибки
     *
     * @param {*} error
     */
    static error( error ) {
        if ( error.stop !== true && error.transition === true ) {
            if ( error.data.forbidden === true ) {
                alert( _ERROR_PREFIX + 'Доступ запрещен!' );
                return;
            }

            alert( _ERROR_PREFIX + 'Произошла ошибка во время обработки перехода' );
        }
    }

    /**
     * Поиск перехода по префиксу
     *
     * @param {string} prefix
     * @return {undefined|Transition}
     * @private
     */
    static _find( prefix ) {
        return find( TRANSITIONS_LIST, transition => transition.prefix === prefix );
    }

    /**
     * Проверяем доступ пользователя по допустимым маршрутам
     *
     * <strong>
     *      Метод реализует только первичную проверка доступа, если требуется контроль более низкого уровня, то
     *      следует проводить дополнительные проверки в кастомном обработчике маршрутизатора, т.е переопределить
     *      Router._handler() в Transition._init() с помощью setter'а и использовать статический метод
     *      Router.forbidden(), там, где необходимо прервать обработку. Этот метод бросит понятное для обработчика
     *      исключение с запретом доступа.
     * </strong>
     *
     * @param {Transition} transition
     * @return {boolean}
     */
    static _accessor( transition ) {
        let access = false;

        let routes = transition.router.routes;
        if ( !Array.isArray( routes ) ) {
            routes = [ transition.router.routes ];
        }

        let userAuth = Vue.auth.check();
        let userRoles = userAuth ? Vue.auth.user().roles : [];
        forEach( routes, route => {
            /**
             * Доступ разрешен, если:
             * - доступ в маршруте не определен + пользователь не авторизован
             * - доступ в маршруте определен + пользователь авторизован
             * - доступ в маршруте по ролям + роль пользователя соответствует роли маршрута (хотя бы одного)
             */
            let routeAccess = route.meta.auth;
            if (
                ( typeof routeAccess === 'undefined' && !userAuth ) ||
                ( routeAccess === true && userAuth ) ||
                userRoles.some( role => routeAccess.includes( role ) )
            ) {
                access = true;
                return false;
            }
        } );

        return access;
    }
}

export default TransitionHandler;
