<template>
    <b-modal
        @hidden='hideModal'
        ref='CheckMultiTasksCreatingProductionActModal'
        id='check-multi-tasks-creating-production-act-modal'
        title="Создание акта выпуска по нескольким заданиям"
    >
        <modal-error v-if="ErrorsBag.state()"></modal-error>
        <modal-spinner v-else-if="Loader.state()"></modal-spinner>

        <missing-raw-materials
            @goTo="hideModal"
            v-model="materials"
            :storage-id="storageId"
            v-show="!Loader.state() && !ErrorsBag.state()"
        ></missing-raw-materials>

        <template #modal-footer>
            <div class="w-100">
                <b-row align-h="around">
                    <b-button variant="default" size="lg" @click="hideModal" v-if="!ErrorsBag.state()">Отмена</b-button>
                    <b-button variant="success" size="lg" @click="createProductionAct" v-if="actionAccessible">
                        Начать выпуск
                    </b-button>
                </b-row>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import moment from 'moment';

    import Loader from '../../../utils/loader';
    import ErrorsBag from '../../../utils/errorsBag';

    import ModalError from '../../_common/ModalError';
    import ModalSpinner from '../../_common/ModalSpinner';

    import MissingRawMaterials from './bodies/MissingRawMaterials';

    import { PRODUCTION_ACTS_ENDPOINT } from '../../../utils/endpoints';

    export default {
        name: "CheckMultiTasksCreatingProductionAct",
        components: { ModalError, ModalSpinner, MissingRawMaterials },
        data() {
            return{
                Loader,
                ErrorsBag,

                shift: null,
                storage: null,
                materials: [],
                productionTasks: []
            };
        },
        computed: {
            storageId() {
                return this.storage ? this.storage.id : null;
            },
            actionAccessible() {
                let successLoading = !Loader.state() && !ErrorsBag.state();
                return successLoading && this.productionTasks.length > 0 && this.storage && this.shift;
            }
        },
        methods: {
            initModal( storage, shift, tasks ) {
                this.clearModal();

                if ( !storage || !shift || !tasks ) {
                    return null;
                }

                this.shift = shift;
                this.storage = storage;
                this.productionTasks = tasks;

                this.productionTasks.map( task => {
                    task.stuff.map( stuff => {
                        let index =
                            this.materials.findIndex( material => material.nomenclature.id === stuff.nomenclature.id );

                        if ( index !== -1 ) {
                            this.materials[ index ].count += parseFloat( stuff.count );
                        } else {
                            this.materials.push( {
                                nomenclature: stuff.nomenclature,
                                count: parseFloat( stuff.count )
                            } );
                        }
                    } )
                } );

                this.$refs.CheckMultiTasksCreatingProductionActModal.show();
            },
            hideModal() {
                this.$refs.CheckMultiTasksCreatingProductionActModal.hide();
                this.clearModal();
            },
            clearModal() {
                ErrorsBag.discard();

                this.shift = null;
                this.storage = null;
                this.materials = [];
                this.productionTasks = [];
            },
            createProductionAct() {
                let params = {
                    rawMaterials: this.materials,
                    use_proportional_raw_materials: 0,
                    storage: { id: this.storage.id },
                    shift: { id: this.shift.id },
                    start_at: moment().format( 'YYYY-MM-DD HH:mm:ss' ),
                    nomenclature: { id: this.productionTasks[ 0 ].nomenclature.id },
                    tasks: this.productionTasks.map( task => { return { id: task.id }; } ),
                };

                return this.$http
                    .post( PRODUCTION_ACTS_ENDPOINT, params )
                    .then( response => {
                        this.$emit( 'callback', response.data.data );
                        this.hideModal();
                    } )
                    .catch( response => ErrorsBag.fill( response.body ) );
            }
        }
    }
</script>
