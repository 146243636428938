'use strict';

import {ROLE_CODE_QUALITY_CONTROL, ROLE_CODE_STORAGE_SENIOR} from '@constants'

export default [
    {
        name: 'ChecklistTemplates',
        path: '/admin/checklist-templates',
        component: () => import('@views/admin/checklist-templates/Index.vue'),
        meta: {
            breadcrumb: 'Администрирование / Шаблоны чек-листов саннорм',
            auth: [ROLE_CODE_QUALITY_CONTROL],
        }
    },
    {
        name: 'ChecklistTemplateCreate',
        path: '/admin/checklist-templates',
        component: () => import('@views/admin/checklist-templates/Form.vue'),
        meta: {
            breadcrumb: 'Администрирование / Шаблоны чек-листов саннорм / Создание',
            auth: [ROLE_CODE_QUALITY_CONTROL],
        }
    },
    {
        name: 'ChecklistTemplateEdit',
        path: '/admin/checklist-templates/:id',
        props: true,
        component: () => import('@views/admin/checklist-templates/Form.vue'),
        meta: {
            breadcrumb: 'Администрирование / Шаблоны чек-листов саннорм / Редактирование',
            auth: [ROLE_CODE_QUALITY_CONTROL],
        }
    },
    {
        name: 'Planning',
        path: '/quality-control/planning-sanitary-inspections',
        component: () => import('@views/quality-control/planning-sanitary-inspections/Index.vue'),
        meta: {
            breadcrumb: 'Контроль качества / Планирование проверок саннорм',
            auth: [ROLE_CODE_QUALITY_CONTROL],
        }
    },
    {
        name: 'PlanningCreate',
        path: '/quality-control/planning-sanitary-inspections',
        component: () => import('@views/quality-control/planning-sanitary-inspections/Form.vue'),
        meta: {
            breadcrumb: 'Контроль качества / Планирование проверок саннорм / Создание',
            auth: [ROLE_CODE_QUALITY_CONTROL],
        }
    },
    {
        name: 'PlanningEdit',
        path: '/quality-control/planning-sanitary-inspections/:id',
        props: true,
        component: () => import('@views/quality-control/planning-sanitary-inspections/Form.vue'),
        meta: {
            breadcrumb: 'Контроль качества / Планирование проверок саннорм / Редактирование',
            auth: [ROLE_CODE_QUALITY_CONTROL],
        }
    },
    {
        name: 'PlannedSanitaryInspections',
        path: '/quality-control/planned-sanitary-inspections',
        component: () => import('@views/quality-control/planned-sanitary-inspections/Index.vue'),
        meta: {
            breadcrumb: 'Контроль качества / Запланированные проверки саннорм',
            auth: [ROLE_CODE_QUALITY_CONTROL, ROLE_CODE_STORAGE_SENIOR],
        }
    },
    {
        name: 'PlannedSanitaryInspectionShow',
        path: '/quality-control/planned-sanitary-inspections/:id',
        props: true,
        component: () => import('@views/quality-control/planned-sanitary-inspections/Form.vue'),
        meta: {
            breadcrumb: 'Контроль качества / Запланированные проверки саннорм',
            auth: [ROLE_CODE_QUALITY_CONTROL, ROLE_CODE_STORAGE_SENIOR],
        }
    },
    {
        name: 'LongTermSanitaryInspections',
        path: '/quality-control/long-term-sanitary-inspections',
        component: () => import('@views/quality-control/long-term-sanitary-inspections/Index.vue'),
        meta: {
            breadcrumb: 'Контроль качества / Долгосрочные задачи саннорм',
            auth: [ROLE_CODE_QUALITY_CONTROL, ROLE_CODE_STORAGE_SENIOR],
        }
    },
    {
        name: 'SanitaryInspectionActList',
        path: '/quality-control/sanitary-inspection-acts',
        component: () => import('@views/quality-control/sanitary-inspection-acts/Index.vue'),
        meta: {
            breadcrumb: 'Контроль качества / Акты проверки санитарных норм',
            auth: [ROLE_CODE_QUALITY_CONTROL],
        }
    },
    {
        name: 'SanitaryInspectionActCreate',
        path: '/quality-control/sanitary-inspection-acts',
        component: () => import('@views/quality-control/sanitary-inspection-acts/Form.vue'),
        meta: {
            breadcrumb: 'Контроль качества / Акты проверки санитарных норм / Создание',
            auth: [ROLE_CODE_QUALITY_CONTROL],
        }
    },
    {
        name: 'SanitaryInspectionActUpdate',
        path: '/quality-control/sanitary-inspection-acts/:id',
        component: () => import('@views/quality-control/sanitary-inspection-acts/Form.vue'),
        props: true,
        meta: {
            breadcrumb: 'Контроль качества / Акты проверки санитарных норм / Редактирование',
            auth: [ROLE_CODE_QUALITY_CONTROL],
        }
    },
];
