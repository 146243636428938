'use strict';

export const ROLE_CODE_ACCOUNTANT = 'ROLE_ACCOUNTANT';
export const ROLE_CODE_ADMIN = 'ROLE_ADMIN';
export const ROLE_CODE_AHO_EMPLOYEE = 'CODE_AHO_EMPLOYEE';
export const ROLE_CODE_AHO_HEAD = 'CODE_AHO_HEAD';
export const ROLE_CODE_COOK = 'ROLE_COOK';
export const ROLE_CODE_DIRECTOR = 'ROLE_DIRECTOR';
export const ROLE_CODE_GROUP_SENIOR = 'ROLE_GROUP_SENIOR';
export const ROLE_CODE_ITO_EMPLOYEE = 'CODE_ITO_EMPLOYEE';
export const ROLE_CODE_ITO_HEAD = 'CODE_ITO_HEAD';
export const ROLE_CODE_LABELER = 'ROLE_LABELER';
export const ROLE_CODE_PLANNER = 'ROLE_PLANNER';
export const ROLE_CODE_QUALITY_CONTROL = 'ROLE_QUALITY_CONTROL';
export const ROLE_CODE_SHIFT_SUPERVISOR = 'ROLE_SHIFT_SUPERVISOR';
export const ROLE_CODE_STOCKMAN = 'ROLE_STOCKMAN';
export const ROLE_CODE_STOCKMAN_SENIOR = 'ROLE_STOCKMAN_SENIOR';
export const ROLE_CODE_STORAGE_SENIOR = 'ROLE_STORAGE_SENIOR';
