'use strict';

import {PRODUCTION_PLANS_ENDPOINT} from '@utils/endpoints.js';
import HttpClient                  from './http-client';

class ProductionPlanService {
    constructor() {
        this.uri = PRODUCTION_PLANS_ENDPOINT;
    }

    async getOperationsOfArea(id, params) {
        const response = await HttpClient.get(`${this.uri}/${id}/operations-of-area`, {params: params});

        return response.data;
    }
}

export default new ProductionPlanService();
