import Vue from 'vue';
import Router from 'vue-router';

import Loader from '../utils/loader';
import ErrorsBag from '../utils/errorsBag';

import analytics from './analytics';
import storageRoutes from './storage';
import departmentRoutes from './department';
import storageReportRoutes from './storage-report';
import shiftReportRoutes from './shift-report';
import admissionActRoutes from './admission-act';
import correctionActRoutes from './correction-act';
import productionActRoutes from './production-act';
import shiftClosingActRoutes from './shift-closing-act';
import productionTaskRoutes from './production-task';
import materialTransferActRoutes from './material-transfer-act';
import boxRoutes from './box';
import auth from './auth';
import operationRoutes from './operation';
import Forbidden from '../components/403';
import OrderToContractorActRoutes from './order-to-contractor-act';
import ServiceRoutes from './services';
import LabelingRoutes from './labeling';
import ProductionOrderRoutes from './production-order';
import QualityControlActRoutes from './quality-control-act';
import InventoryActRoutes from './inventory-act';
import ProductionTasksReportRoutes from './production-tasks-report';
import settings from './settings';
import PlanningRoutes from './planning.js';

import ReportsRoutes from './reports';

import UsersRoutes from './users';
import SalaryRoutes from './salary';
import WorkPositionsRoutes from './work-positions';
import SpecificationsRoutes from './specifications';
import WeighingScalesRoutes from './weighing-scales';

import SanitaryInspectionRoutes from './sanitary-inspection';

import AreaRoutes from './areas';

// добавляем возможность открытия страниц роутера в новой вкладке.
Router.prototype.open = function (routeObject) {
    const {href} = this.resolve(routeObject);
    window.open(href, '_blank');
};

Vue.use(Router);

const routes = [
    ...auth,
    ...analytics,
    ...storageRoutes,
    ...departmentRoutes,
    ...storageReportRoutes,
    ...shiftReportRoutes,
    ...admissionActRoutes,
    ...correctionActRoutes,
    ...productionActRoutes,
    ...shiftClosingActRoutes,
    ...productionTaskRoutes,
    ...materialTransferActRoutes,
    ...boxRoutes,
    ...operationRoutes,
    ...OrderToContractorActRoutes,
    ...ServiceRoutes,
    ...LabelingRoutes,
    ...PlanningRoutes,
    ...ProductionOrderRoutes,
    ...QualityControlActRoutes,
    ...InventoryActRoutes,
    ...ProductionTasksReportRoutes,

    ...ReportsRoutes,

    ...UsersRoutes,
    ...SalaryRoutes,
    ...WorkPositionsRoutes,
    ...SpecificationsRoutes,
    ...WeighingScalesRoutes,

    ...SanitaryInspectionRoutes,

    ...AreaRoutes,

    ...settings,
    {
        path: '/403',
        component: Forbidden
    },
    {
        path: '*',
        redirect: '/login'
    }
];

const router = new Router({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    Loader.discard();
    ErrorsBag.discard();

    next();
});

export {routes};
export default router;
