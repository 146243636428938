import {
    ROLE_ACCOUNTANT,
    ROLE_STOCKMAN,
    ROLE_STORAGE_SENIOR,
    ROLE_STOCKMAN_SENIOR
} from "@utils/Roles";

export default [
    {
        name: 'InventoryActList',
        path: '/inventory-acts',
        component: () => import('@components/inventoryact/InventoryActList'),
        meta: {
            label: 'Акты инвентаризации',
            auth: [ROLE_ACCOUNTANT, ROLE_STORAGE_SENIOR, ROLE_STOCKMAN_SENIOR, ROLE_STOCKMAN],
            fullsize: true
        }
    },
    {
        name: 'InventoryActCreate',
        path: '/inventory-acts',
        component: () => import('@components/inventoryact/InventoryActForm'),
        meta: {
            label: 'Акты инвентаризации / Создание',
            auth: [ROLE_ACCOUNTANT, ROLE_STORAGE_SENIOR, ROLE_STOCKMAN_SENIOR, ROLE_STOCKMAN],
            fullsize: true
        }
    },
    {
        name: 'InventoryActUpdate',
        path: '/inventory-acts/:id',
        component: () => import('@components/inventoryact/InventoryActForm'),
        meta: {
            label: 'Акты инвентаризации / Редактирование',
            auth: [ROLE_ACCOUNTANT, ROLE_STORAGE_SENIOR, ROLE_STOCKMAN_SENIOR, ROLE_STOCKMAN],
            fullsize: true
        }
    }
]
