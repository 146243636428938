class ErrorHandler {
    assert(response, defaultMessage) {
        if (response.ok) {
            return;
        }

        let message = defaultMessage;
        if (!!response.data.message) {
            message += ": " + response.data.message;
        }

        if (!!response.data.errors) {

            if (Array.isArray(response.data.errors) && response.data.errors.length) {
                response.data.errors.map(e => {
                    message += "\n" + e.toString();
                });
            } else if (Object.keys(response.data.errors).length) {
                Object.keys(response.data.errors).map(k => {
                    message += "\n" + response.data.errors[k].toString();
                });
            }
        }

        alert(message);
    }
}

export default new ErrorHandler;
