import {ROLE_CODE_QUALITY_CONTROL, ROLE_CODE_STORAGE_SENIOR, ROLE_CODE_STOCKMAN} from '@constants'

export default [
    {
        name: 'QualityControlActList',
        path: '/quality-control-acts',
        component: () => import('@views/quality-control/quality-control-acts/Index.vue'),
        meta: {
            breadcrumb: 'Контроль качества / Акты контроля качества',
            auth: [ROLE_CODE_STORAGE_SENIOR, ROLE_CODE_QUALITY_CONTROL],
            fullsize: true
        }
    },
    {
        name: 'QualityControlActCreate',
        path: '/quality-control-acts',
        component: () => import('@views/quality-control/quality-control-acts/Form.vue'),
        meta: {
            breadcrumb: 'Контроль качества / Акты контроля качества / Создание',
            auth: [ROLE_CODE_QUALITY_CONTROL]
        }
    },
    {
        name: 'QualityControlActUpdate',
        path: '/quality-control-acts/:id',
        component: () => import('@views/quality-control/quality-control-acts/Form.vue'),
        props: true,
        meta: {
            breadcrumb: 'Контроль качества / Акты контроля качества / Редактирование',
            auth: [ROLE_CODE_QUALITY_CONTROL],
        }
    },
    {
        name: 'QualityControlActShow',
        path: '/quality-control-acts/:id',
        component: () => import('@views/quality-control/quality-control-acts/Show.vue'),
        props: true,
        meta: {
            breadcrumb: 'Контроль качества / Акты контроля качества / Просмотр',
            auth: [ROLE_CODE_STOCKMAN, ROLE_CODE_STORAGE_SENIOR, ROLE_CODE_QUALITY_CONTROL],
        }
    },
];
