import {ROLE_ADMIN} from "@utils/Roles";

export default [
    {
        name: 'Operations',
        path: '/operations',
        component: () => import('@views/operations/Index'),
        meta: {
            breadcrumb: 'Еще / Операции',
            auth: [ROLE_ADMIN],
        }
    },
    {
        name: 'OperationUpdate',
        path: '/operations/:id',
        component: () => import('@views/operations/Form'),
        props: true,
        meta: {
            breadcrumb: 'Еще / Операции / Редактирование',
            auth: [ROLE_ADMIN]
        }
    }
]
