import { $app as App } from '../../../../main';
import { SALARY_ENDPOINT } from '../../../../utils/endpoints';

export default {
    namespaced: true,
    state: {
        statuses: []
    },
    mutations: {
        set_statuses( state, statuses ) {
            state.statuses = statuses;
        }
    },
    actions: {
        fetchStatuses( { commit } ) {
            let params = { orders: { id: 'asc' }, limit: 100, without_loading: true };

            App.$http
                .get( SALARY_ENDPOINT + '/settings/kpi-statuses', { params } )
                .then( response => commit( 'set_statuses', response.data.data.data ) );
        },
    }
};
