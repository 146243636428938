'use strict';

import {AREAS_ENDPOINT} from '@utils/endpoints';
import HttpClient       from './http-client';

class AreaService {
    constructor() {
        this.uri = AREAS_ENDPOINT;
    }

    /**
     * @param {Object} [params]
     * @param {Object[]} [params.filters]
     * @param {String[]} [params.with]
     * @param {Number} [params.page]
     * @param {Number} [params.per_page]
     *
     * @returns {Promise<{data, meta: {per_page, total_rows, current_page}}, Object>}
     */
    async getWithPaginate(params) {
        const response = await HttpClient.get(this.uri, {params: {params}});

        return {
            data: response.data.data,
            meta: {
                current_page: response.data.data.current_page,
                per_page: response.data.data.per_page,
                total_rows: response.data.data.total,
            },
        };
    }


    /**
     * @param {Number} id
     * @param {Object} [params]
     * @param {String[]} [params.with]
     *
     * @returns {Promise<Object, Object>}
     */
    async getById(id, params) {
        const response = await HttpClient.get(`${this.uri}/${id}`, {params: {params}});

        return response.data;
    }

    /**
     *
     * @param {Number} id
     * @param {String} date
     * @returns {Promise<Object, Object>}
     */
    async getWorkload(id, date) {
        const response =  await HttpClient.get(
            `${this.uri}/${id}/workload`,
            {
                params: {
                    filters: {
                        date,
                    },
                    without_loading: true,
                }
            }
        );

        return {
            limit: response.data.limit,
            workload: response.data.workload,
        };
    }

    async update(id, params) {
        const response = await HttpClient.put(`${this.uri}/${id}`, params);

        return response.data;
    }
}

export default new AreaService();
