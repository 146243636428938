import {
    ROLE_DIRECTOR,
    ROLE_ACCOUNTANT,
    ROLE_GROUP_SENIOR,
    ROLE_STOCKMAN_SENIOR,
    CODE_AHO_HEAD,
    CODE_ITO_HEAD
} from '@utils/Roles';

export default [
    /* Зарплата */
    {
        name: 'SalaryDashboard',
        path: '/salary',
        component: () => import('@components/salary/SalaryDashboard'),
        meta: {
            label: 'Зарплата сотрудников',
            auth: [ROLE_ACCOUNTANT, ROLE_GROUP_SENIOR, ROLE_STOCKMAN_SENIOR, CODE_AHO_HEAD, CODE_ITO_HEAD],
            fullsize: true
        }
    },
    {
        name: 'SalarySettings',
        path: '/admin/salary-settings',
        component: () => import('@components/salary/SalarySettings'),
        meta: {
            label: 'Администрирование / Настройка зарплаты',
            auth: [ROLE_ACCOUNTANT],
            fullsize: true
        }
    },

    /* Промежутки стоимости должностей */
    {
        props: true,
        name: 'SalaryRatesList',
        path: '/admin/salary-settings/:id/rates',
        component: () => import('@components/salary/rates/SalaryRatesList'),
        meta: {
            label: 'Администрирование / Список промежутков стоимости должности',
            auth: [ROLE_ACCOUNTANT],
            fullsize: true
        }
    },
    {
        props: true,
        name: 'SalaryRatesCreate',
        path: '/admin/salary-settings/:id/rates',
        component: () => import('@components/salary/rates/SalaryRatesCreate'),
        meta: {
            label: 'Администрирование / Создание промежутка стоимости должности',
            auth: [ROLE_ACCOUNTANT]
        }
    },
    {
        props: true,
        name: 'SalaryRatesEdit',
        path: '/admin/salary-settings/:id/rates/:rate_id',
        component: () => import('@components/salary/rates/SalaryRatesEdit'),
        meta: {
            label: 'Администрирование / Редактирование промежутка стоимости должности',
            auth: [ROLE_ACCOUNTANT]
        }
    },

    /* Настройка вознаграждения */
    {
        props: true,
        name: 'SalaryBonusList',
        path: '/salary/bonus',
        component: () => import('@components/salary/bonus/SalaryBonusList'),
        meta: {
            label: 'Настройки вознаграждений',
            auth: [ROLE_DIRECTOR],
            fullsize: true
        }
    },
    {
        props: true,
        name: 'SalaryBonusCreate',
        path: '/salary/bonus',
        component: () => import('@components/salary/bonus/SalaryBonusCreate'),
        meta: {
            label: 'Создание вознаграждения',
            auth: [ROLE_DIRECTOR]
        }
    },
    {
        props: true,
        name: 'SalaryBonusEdit',
        path: '/salary/bonus/:id',
        component: () => import('@components/salary/bonus/SalaryBonusEdit'),
        meta: {
            label: 'Редактирование вознаграждения',
            auth: [ROLE_DIRECTOR]
        }
    },

    /* Табель рабочего времени */
    {
        name: 'TimesheetDashboard',
        path: '/salary/timesheet',
        component: () => import('@components/salary/timesheet/TimesheetDashboard'),
        meta: {
            label: 'Администрирование / Табель',
            auth: [ROLE_ACCOUNTANT, ROLE_GROUP_SENIOR, ROLE_STOCKMAN_SENIOR, CODE_AHO_HEAD, CODE_ITO_HEAD],
            fullsize: true
        }
    },
    {
        props: true,
        name: 'TimesheetEdit',
        path: '/salary/timesheet/:id',
        component: () => import('@components/salary/timesheet/TimesheetEdit'),
        meta: {
            label: 'Администрирование / Табель / Редактирование',
            auth: [ROLE_ACCOUNTANT, ROLE_GROUP_SENIOR, ROLE_STOCKMAN_SENIOR, CODE_AHO_HEAD, CODE_ITO_HEAD],
        }
    },

    /* Надбавки и штрафы */
    {
        name: 'SalaryBountyType',
        path: '/salary/bounty/type',
        component: () => import('@components/salary/bounty/type/SalaryBountyTypes'),
        meta: {
            label: 'Типы надбавок и штрафов',
            auth: [ROLE_ACCOUNTANT, ROLE_GROUP_SENIOR, ROLE_STOCKMAN_SENIOR, CODE_AHO_HEAD, CODE_ITO_HEAD]
        }
    },
    {
        name: 'SalaryBounty',
        path: '/salary/bounty',
        component: () => import('@components/salary/bounty/SalaryBounty'),
        meta: {
            label: 'Надбавки и штрафы',
            auth: [ROLE_ACCOUNTANT, ROLE_GROUP_SENIOR, ROLE_STOCKMAN_SENIOR, CODE_AHO_HEAD, CODE_ITO_HEAD],
            fullsize: true
        }
    },

    /* Больничные и отпускные */
    {
        name: 'DocumentList',
        path: '/salary/documents',
        component: () => import('@views/admin/documents/Index.vue'),
        meta: {
            label: 'Администрирование / Документы',
            auth: [ROLE_ACCOUNTANT, ROLE_GROUP_SENIOR, ROLE_STOCKMAN_SENIOR, CODE_AHO_HEAD, CODE_ITO_HEAD],
            fullsize: true
        }
    },
    {
        name: 'DocumentCreate',
        path: '/salary/documents',
        component: () => import('@views/admin/documents/Form.vue'),
        meta: {
            label: 'Администрирование / Документы / Создание',
            auth: [ROLE_ACCOUNTANT, ROLE_GROUP_SENIOR, ROLE_STOCKMAN_SENIOR, CODE_AHO_HEAD, CODE_ITO_HEAD],
            fullsize: true
        }
    },
    {
        name: 'DocumentUpdate',
        path: '/salary/documents/:id',
        component: () => import('@views/admin/documents/Form.vue'),
        props: true,
        meta: {
            label: 'Администрирование / Документы / Редактирование',
            auth: [ROLE_ACCOUNTANT, ROLE_GROUP_SENIOR, ROLE_STOCKMAN_SENIOR, CODE_AHO_HEAD, CODE_ITO_HEAD],
            fullsize: true
        }
    },
]
