import { BOX_OBJECT_TYPE } from "./objectTypes";
import { BARCODES_ENDPOINT, NOMENCLATURE_LOTS_ENDPOINT } from "./endpoints";

const BarcodeRepository = {

    app: null,
    boxPrefixes: ['QZ', 'QS'],
    nomenclatureLotPrefixes: ['N'],

    findFirstResourceByBarcode(search) {
        return BarcodeRepository.findBarcode(search)
            .then(response => {
                if (!response) {
                    // Если не нашли штрих код, то, возможно, это ящик
                    for (let i = 0; i < this.boxPrefixes.length; i++) {
                        if (search.startsWith(this.boxPrefixes[i])) {
                            return {
                                object: {
                                    objectType: BOX_OBJECT_TYPE
                                },
                                resource: {
                                    name: search
                                }
                            }
                        }
                    }

                    return null
                }

                return  {
                    object: response.data.object,
                    resource: response.data.resource
                }
            })
    },

    findBarcode(search) {
        return this.app.http
            .get(BARCODES_ENDPOINT + '/find', {
                params: {
                    code: search,
                    with: [
                        'box',
                        'nomenclature',
                        'containerMeasureUnit',
                    ]
                }
            })
            .then(response => {

                if (!response.data.object) {
                    return null
                }

                return response;
            })
            .catch(() => {
                return null
            })
    },

    findLastLotByNomenclature(nomenclature_id) {
        return this.app.http
            .get(NOMENCLATURE_LOTS_ENDPOINT + '/index', {
                params: {
                    filters: {
                        nomenclature_id
                    },
                    sort: 'id',
                    sortDirection: 'desc',
                    without_loading: true
                }
            })
            .then(response => response.data.data.data)
            .then(lots => lots.shift() || null);
    },

    /**
     * Plugin install
     *
     * @param Vue
     * @param options
     */
    install(Vue, options) {
        this.app = Vue
    }
};

export default BarcodeRepository
