import { ROLE_ADMIN, ROLE_SHIFT_SUPERVISOR } from "@utils/Roles";

export default [
    {
        name: 'ServiceList',
        path: '/services/list',
        component: () => import('@components/services/ServiceList'),
        meta: {
            label: 'Сервисы',
            auth: [ ROLE_ADMIN, ROLE_SHIFT_SUPERVISOR ]
        }
    },
    {
        name: 'PrintQueueList',
        path: '/services/print-queue',
        component: () => import('@components/services/printqueue/PrintQueueList'),
        meta: {
            fullsize: true,
            label: 'Очередь печати',
            auth: [ ROLE_ADMIN ]
        }
    }
];
