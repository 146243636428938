export const LARAVEL_PREFIX = 'v2/';

export const STORAGE_ENDPOINT = LARAVEL_PREFIX + 'storages';
export const STORAGE_REPORTS_ENDPOINT = STORAGE_ENDPOINT + '/report';

export const DEPARTMENT_ENDPOINT = LARAVEL_PREFIX + 'departments';
export const OPERATIONS_ENDPOINT = LARAVEL_PREFIX + 'operations';
export const NOMENCLATURES_ENDPOINT = LARAVEL_PREFIX + 'nomenclatures';
export const SPECIFICATIONS_ENDPOINT = LARAVEL_PREFIX + 'specifications';
export const MARKUP_PRESETS_ENDPOINT = LARAVEL_PREFIX + 'markup_presets';
export const NOMENCLATURE_LOTS_ENDPOINT = LARAVEL_PREFIX + 'nomenclature_lots';

export const PRODUCTION_ACTS_ENDPOINT = LARAVEL_PREFIX + 'production_acts';
export const PRODUCTION_TASKS_ENDPOINT = LARAVEL_PREFIX + 'production_tasks';
export const PRODUCTION_PLANS_ENDPOINT = LARAVEL_PREFIX + 'production_plans';
export const PRODUCTION_ORDERS_ENDPOINT = LARAVEL_PREFIX + 'production_orders';
export const PRODUCTION_PLAN_REASONS_ENDPOINT = LARAVEL_PREFIX + 'production_plan_reasons';

export const ADMISSION_ACTS_RESOURCE_ENDPOINT = LARAVEL_PREFIX + 'admission_acts';
export const SHIFT_CLOSING_ACT_ENDPOINT = LARAVEL_PREFIX + 'shift_closing_acts';
export const SHIPMENT_ACT_ENDPOINT = LARAVEL_PREFIX + 'shipment-acts';
export const ORDER_TO_CONTRACTOR_ACTS_ENDPOINT = LARAVEL_PREFIX + 'order_to_contractor_acts';
export const MATERIAL_TRANSFER_ACT_ENDPOINT = LARAVEL_PREFIX + 'material_transfer';

export const PACK_TRANSFER_ACT_DATA_PATH = LARAVEL_PREFIX + 'pack_transfer/act_data';
export const PACK_TRANSFER_INFO_BY_BARCODE_PATH = LARAVEL_PREFIX + 'pack_transfer/info_by_barcode';

export const STORAGE_MOVEMENT_ENDPOINT = LARAVEL_PREFIX + 'storage_movement';
export const PRODUCTION_SHIFT_ENDPOINT = LARAVEL_PREFIX + 'production_shift';

export const STORAGE_TASKS_ENDPOINT = LARAVEL_PREFIX + 'tasks/storage';
export const MATERIAL_TRANSFER_CORRECTION_ENDPOINT = LARAVEL_PREFIX + 'material_transfer/correction';
export const MATERIAL_TRANSFER_CORRECTION_REASON_ENDPOINT = LARAVEL_PREFIX + 'material_transfer/correction/reasons';
export const ACT_TYPE_ENDPOINT = LARAVEL_PREFIX + 'act_type';
export const ACT_STATUSES_ENDPOINT = LARAVEL_PREFIX + 'act-statuses';

export const LABELING_RESTRICTIONS_ENDPOINT = LARAVEL_PREFIX + 'labeling_restrictions';
export const PACKING_ENDPOINT = LARAVEL_PREFIX + 'packing';

export const STOCK_ENDPOINT = LARAVEL_PREFIX + 'stock';

export const BARCODES_ENDPOINT = LARAVEL_PREFIX + 'barcodes';

export const ROLES_ENDPOINT = LARAVEL_PREFIX + 'roles';
export const USERS_ENDPOINT = LARAVEL_PREFIX + 'users';
export const SALARY_ENDPOINT = LARAVEL_PREFIX + 'salary';
export const WORK_POSITIONS_ENDPOINT = LARAVEL_PREFIX + 'work_positions';

export const QUALITY_CONTROL_ACT_ENDPOINT = LARAVEL_PREFIX + 'quality_control_acts';
export const SANITARY_INSPECTION_ACT_ENDPOINT = LARAVEL_PREFIX + 'sanitary_inspection_act';
export const QUALITY_CONTROL_ACT_REASONS_ENDPOINT = LARAVEL_PREFIX + 'quality_control_act_reasons';

export const WEIGHING_SCALES_ENDPOINT = LARAVEL_PREFIX + 'devices/scales';
export const PRINTER_ENDPOINT = LARAVEL_PREFIX + 'devices/printer';
export const BOX_ENDPOINT = LARAVEL_PREFIX + 'box';

export const CONTRACTORS_ENDPOINT = LARAVEL_PREFIX + 'contractors';
export const MANUFACTURER_ENDPOINT = LARAVEL_PREFIX + 'manufacturer';

export const PRINT_QUEUES = LARAVEL_PREFIX + 'print-queues';

export const REPORTS = LARAVEL_PREFIX + 'reports';
export const SANITARY_INSPECTION_REPORT = REPORTS + '/sanitary_inspection';
export const DOCUMENT_ENDPOINT = SALARY_ENDPOINT + '/documents';

export const FOOD_COST_ENDPOINT = LARAVEL_PREFIX + 'analytics/food-cost';
export const OPERATION_COST_ENDPOINT = LARAVEL_PREFIX + 'analytics/operation-cost';
export const OPERATION_COST_DETAILS_ENDPOINT = LARAVEL_PREFIX + 'analytics/operation-cost/details';

export const SETTINGS_ENDPOINT = LARAVEL_PREFIX + 'settings'

export const CORRECTION_ACTS_ENDPOINT = LARAVEL_PREFIX + 'correction-acts';

export const INVENTORY_ACTS_ENDPOINT = LARAVEL_PREFIX + 'inventory_acts';

export const CHECKLIST_TEMPLATES_ENDPOINT = LARAVEL_PREFIX + 'checklist-templates';
export const PLANNING_SANITARY_INSPECTIONS_ENDPOINT = LARAVEL_PREFIX + 'planning-sanitary-inspections';
export const PLANNED_SANITARY_INSPECTIONS_ENDPOINT = LARAVEL_PREFIX + 'planned-sanitary-inspections';
export const PLANNED_SANITARY_INSPECTION_ITEMS_ENDPOINT = LARAVEL_PREFIX + 'planned-sanitary-inspection-items';
export const LONG_TERM_SANITARY_INSPECTIONS_ENDPOINT = LARAVEL_PREFIX + 'long-term-sanitary-inspections'

export const AREAS_ENDPOINT = LARAVEL_PREFIX + 'areas';
