<template>
    <div id="app" v-if="$auth.ready()" class="app ready">
        <loading :show="Loader.state()" label="Загрузка..."/>
        <the-sidebar v-if="$auth.check()"/>
        <c-wrapper>
            <the-header
                v-if="$auth.check()"
                class="sticky-top d-print-none"
                @shouldUpdate="shouldUpdate"
                @reload="reload"
                @logout="logout"
            />

            <b-container fluid>
                <transition name="fade" mode="out-in">
                    <router-view/>
                </transition>
            </b-container>
        </c-wrapper>

        <b-modal
            id="barcode-input-modal"
            title="Введите ШК"
            centered
            @ok="sendBarcode"
            ok-title="Подтвердить"
            ok-variant="success"
            cancel-title="Отмена"
        >
            <b-form-input v-model="barcode"/>
        </b-modal>

        <globals ref="globals"></globals>
    </div>
</template>

<script>
import Globals from './Globals';
import loading from 'vue-full-loading';
import {mapActions, mapState} from 'vuex';
import Loader from '@utils/loader';
import TheHeader  from './TheHeader';
import TheSidebar from './TheSidebar';
import {CWrapper} from '@coreui/vue';

import {
    ROLE_STORAGE_SENIOR,
    ROLE_STOCKMAN,
    ROLE_SHIFT_SUPERVISOR,
    ROLE_QUALITY_CONTROL,
    ROLE_STOCKMAN_SENIOR,
    ROLE_ACCOUNTANT
} from '@utils/Roles';

import TransitionHandler from '@utils/barcode-transition/Handler';

export default {
    el: '#app',
    name: "App",
    components: {
        Globals,
        loading,
        TheHeader,
        TheSidebar,
        CWrapper
    },
    computed: {
        ...mapState({
            appVersion: state => state.app_version,
            latestAppVersion: state => state.latest_app_version
        }),
        show() {
            return this.$store.state.sidebarShow && this.$auth.check();
        },
        minimize() {
            return this.$store.state.sidebarMinimize && this.$auth.check();
        }
    },
    data() {
        return {
            Loader,
            lastOnBarcodeScannedCallback: null,
            prevOnBarcodeScannedCallback: null,
            lastOnBarcodeScannedComponentName: null,
            prevOnBarcodeScannedComponentName: null,
            barcode: null,
        }
    },
    methods: {
        shouldUpdate() {
            if (!!this.appVersion && !!this.latestAppVersion) {
                return this.appVersion !== this.latestAppVersion;
            }

            return false;
        },
        /**
         * Получаем доступ к глобальным компонентам, через их ref
         *
         * @param name
         * @return {Vue | Element | Vue[] | Element[] | *}
         */
        global(name) {
            return this.$refs['globals'].$refs[name];
        },
        reload() {
            location.reload();
        },
        logout() {
            this.$auth.logout()
        },
        listenToBarcodeScanning(component) {
            if (this.lastOnBarcodeScannedCallback) {
                if (component === this) {
                    console.log('barcode listener already exists');
                    return;
                }
                console.log(`"${this.lastOnBarcodeScannedComponentName}" component barcode listener removed`);
                document.removeEventListener('barcodeScanned', this.lastOnBarcodeScannedCallback);
                this.prevOnBarcodeScannedCallback = this.lastOnBarcodeScannedCallback;
                this.prevOnBarcodeScannedComponentName = this.lastOnBarcodeScannedComponentName;
                this.lastOnBarcodeScannedCallback = null;
                this.lastOnBarcodeScannedComponentName = null;
            }

            let route = this.$route;

            let callback = (e) => {
                TransitionHandler.go(e.data.barcode)
                    .then(response => {
                        if (response.transition === true) {
                            console.log('transition by barcode successfully fulfilled');
                            return;
                        }

                        if (route.name !== this.$route.name) {
                            document.removeEventListener('barcodeScanned', callback);
                            return;
                        }

                        if (typeof component.onBarcodeScanned === 'function') {
                            component.onBarcodeScanned(e.data);
                        } else {
                            console.error('onBarcodeScanned is not defined');
                        }
                    })
                    .catch(error => {
                        TransitionHandler.error(error);
                        throw error;
                    });
            };

            this.lastOnBarcodeScannedCallback = callback;
            if (component && component.$options && component.$options.name) {
                this.lastOnBarcodeScannedComponentName = component.$options.name;
            }
            console.log(`"${this.lastOnBarcodeScannedComponentName}" component barcode listener added`);
            document.addEventListener('barcodeScanned', callback);
        },
        restorePreviousBarcodeScanning() {
            if (this.prevOnBarcodeScannedCallback && this.lastOnBarcodeScannedCallback) {
                console.log(`"${this.lastOnBarcodeScannedComponentName}" component barcode listener removed`);
                document.removeEventListener('barcodeScanned', this.lastOnBarcodeScannedCallback);
                console.log(`"${this.prevOnBarcodeScannedComponentName}" component barcode listener added`);
                document.addEventListener('barcodeScanned', this.prevOnBarcodeScannedCallback);
                this.lastOnBarcodeScannedCallback = this.prevOnBarcodeScannedCallback;
                this.lastOnBarcodeScannedComponentName = this.prevOnBarcodeScannedComponentName;
            }
        },
        sendBarcode() {
            this.barcode = this.barcode.trim();

            let barcodes = this.barcode.split(' ');

            let delay = 0;

            for (let i in barcodes) {
                let barcode = barcodes[i];

                window.setTimeout(() => {
                    let event = new Event('barcodeScanned');
                    event.data = {
                        barcode: barcode
                    };
                    document.dispatchEvent(event);
                }, delay);

                delay += 500;
            }
        },
        ...mapActions({
            refreshAvailableToReceiveCount: 'material_transfer_act/availableToReceiveCount/refresh',
            refreshPlannedSanitaryInspectionCount: 'sanitary_inspections/plannedCount/refresh',
            refreshApprovedCorrectionActCount: 'correction_acts/approvedCount/refresh',
            refreshMaterialTransferActsCreatedByQualityControlAct: 'material_transfer_act/createdByQualityControlActCount/refresh',
        }),
        responseError(response, preMessage = null) {
            if (response.data && response.data.errors && Array.isArray(response.data.errors)) {
                this.$bvToast.toast((preMessage ? preMessage + ': ' : '') + response.data.errors.join("\n"), {variant: 'danger'});
                return;
            }

            if (response.data && response.data.errors) {
                this.$bvToast.toast((preMessage ? preMessage + ': ' : '') + Object.values(response.data.errors).flat().join('<br/>'), {variant: 'danger'});
                return;
            }

            if (response.data && response.data.message) {
                this.$bvToast.toast((preMessage ? preMessage + ': ' : '') + response.data.message + "\nКод: " + response.status, {variant: 'danger'});
                return;
            }

            if (response.data && response.data.messages) {
                this.$bvToast.toast((preMessage ? preMessage + ': ' : '') + response.data.messages.join("\n"), {variant: 'danger'});
                return;
            }

            if (response.status === 0) {
                this.$bvToast.toast('Ошибка запроса. Проверьте соединение с интернетом', {variant: 'danger'});
                return;
            }

            this.$bvToast.toast("Неизвестная ошибка. \nКод: " + response.status, {variant: 'danger'})
        },
        dispatchNotificationEvents() {
            if (this.$auth.check()) {
                this.$store.dispatch('kpi_statuses/fetchStatuses');

                const hasAccess = (roles) => this.$auth.user().roles.some((role) => roles.includes(role));

                if (hasAccess([ROLE_STORAGE_SENIOR, ROLE_STOCKMAN, ROLE_SHIFT_SUPERVISOR])) {
                    this.refreshAvailableToReceiveCount();

                    setInterval(() => {
                        this.refreshAvailableToReceiveCount();
                    }, 300000);
                }

                if (hasAccess([ROLE_QUALITY_CONTROL, ROLE_STORAGE_SENIOR])) {
                    this.refreshPlannedSanitaryInspectionCount();

                    setInterval(() => {
                        this.refreshPlannedSanitaryInspectionCount();
                    }, 300000);
                }

                if (hasAccess([ROLE_STOCKMAN, ROLE_STOCKMAN_SENIOR])) {
                    this.refreshApprovedCorrectionActCount();

                    setInterval(() => {
                        this.refreshApprovedCorrectionActCount();
                    }, 300000);
                }

                if (hasAccess([ROLE_STORAGE_SENIOR, ROLE_STOCKMAN, ROLE_ACCOUNTANT])) {
                    this.refreshMaterialTransferActsCreatedByQualityControlAct();

                    setInterval(() => {
                        this.refreshMaterialTransferActsCreatedByQualityControlAct();
                    }, 300000);
                }
            }
        }
    },
    mounted() {
        this.listenToBarcodeScanning(this);

        this.$auth.ready(() => {
            this.dispatchNotificationEvents();
        });

        document.addEventListener('keyup', (event) => {
            if (event.ctrlKey && event.code === 'KeyB') {
                this.$bvModal.show('barcode-input-modal');
            }
        });
    },
    beforeDestroy() {
        document.removeEventListener('keyup', (event) => {
            if (event.ctrlKey && event.code === 'KeyB') {
                this.$bvModal.show('barcode-input-modal');
            }
        });
    }
}
</script>
<style scoped>
.blink {
    animation: blink 1s infinite;
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
</style>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

@media only screen and (max-width: 340px) {
    .semi-relative {
        position: relative !important;
    }
}

</style>
<style src="bootstrap-vue/dist/bootstrap-vue.min.css"></style>
<style src="@fortawesome/fontawesome-free/css/all.min.css"></style>
<style src="../assets/scss/style.scss" lang="scss"></style>
