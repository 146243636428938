import {ROLE_ADMIN, ROLE_SHIFT_SUPERVISOR, ROLE_STORAGE_SENIOR} from "@utils/Roles";

export default [
    {
        name: 'ShiftClosingActList',
        path: '/shift-closing-acts',
        component: () => import('@components/shiftclosingact/ShiftClosingActList'),
        meta: {
            breadcrumb: 'Акты закрытия смены',
            auth: [ROLE_ADMIN, ROLE_STORAGE_SENIOR]
        }
    },
    {
        name: 'ShiftClosingActCreate',
        path: '/shift-closing-acts/create',
        component: () => import('@components/shiftclosingact/ShiftClosingActForm'),
        meta: {
            breadcrumb: 'Акты закрытия смены / Создание',
            auth: [ROLE_ADMIN, ROLE_STORAGE_SENIOR],
        }
    },
    {
        name: 'ShiftClosingActUpdate',
        path: '/shift-closing-acts/edit/:id',
        component: () => import('@components/shiftclosingact/ShiftClosingActForm'),
        meta: {
            breadcrumb: 'Акты закрытия смены / Редактирование',
            auth: [ROLE_ADMIN, ROLE_STORAGE_SENIOR]
        }
    },
    {
        name: "ShiftClosingWizard",
        path: '/shift-closing-acts/wizard/:id?',
        component: () => import('@components/shiftclosingact/Wizard/Wizard'),
        props: true,
        meta: {
            breadcrumb: 'Акты закрытия смены / Просмотр',
            auth: [ROLE_ADMIN, ROLE_STORAGE_SENIOR, ROLE_SHIFT_SUPERVISOR],
        }
    },
]
