import createdByQualityControlActCount from './createdByQualityControlActCount';
import availableToReceiveCount         from './availableToReceiveCount';

export default {
    namespaced: true,
    modules: {
        createdByQualityControlActCount,
        availableToReceiveCount,
    }
}
