import {ROLE_CODE_ADMIN} from '@constants';

export default [
    {
        name: 'DepartmentList',
        path: '/departments',
        component: () => import('@components/department/DepartmentList'),
        meta: {
            breadcrumb: 'Еще / Подразделения',
            auth: [ROLE_CODE_ADMIN]
        }
    }
]
