import Vue from 'vue';

import { $app } from '../../../main';

import find from 'lodash/find';
import { PRODUCTION_TASKS_ENDPOINT } from '../../../utils/endpoints';

import Router from '../Router';
import Transition from '../Transition';
import ProductionActRoutes from '../../../router/production-act';

const PREFIX = 'PABT';

/**
 * Переход к интерфейсу ProductionAct по ProductionTask.
 * Если такого акта нет, то создаем его, если есть переходим на форму редактирования
 */
class ProductionActByTaskTransition extends Transition {
    /**
     * Инициализируем объект перехода и
     * задаем необходимые параметры (_prefix, _router.routes) и поведение (_router.handler).
     *
     * @override
     * @private
     */
    _init() {
        this._prefix = PREFIX;

        this._router.routes = [ find( ProductionActRoutes, route => route.name === 'ProductionActUpdate' ) ];
        this._router.handler = ( router, parameters ) => {
            let route = router.routes[ 0 ];
            let instance = this.constructor;

            /**
             * Ищем акт выпуска по id задания:
             * - Если акт выпуска найден, то переходим к акту выпуска.
             * - Если акт выпуска отсутствует, то создаем акт выпуска и переходим к нему.
             */
            return Vue.http
                .get( PRODUCTION_TASKS_ENDPOINT + '/' + parameters.taskId + '/production_acts/first' )
                .then( response => {
                    let productionAct = response.data.data;

                    if ( productionAct !== null ) {
                        Vue.router.push( { name: route.name, params: { id: productionAct.id } } );

                        return Router.response( true );
                    }

                    let modals = $app.global( 'modals' );
                    let CheckCreatingProductionActModal = modals.getModal( 'CheckCreatingProductionAct' );
                    CheckCreatingProductionActModal.initModal( parameters.taskId );

                    return Router.response( true );
                }, error => {
                    alert( instance._error( 'Произошла ошибка во время получения акта выпуска' ) );
                    Router.error( true, error);
                } );
        };
    }
}

export default new ProductionActByTaskTransition();
