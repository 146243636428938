import {ROLE_CODE_DIRECTOR, ROLE_CODE_SHIFT_SUPERVISOR} from '@constants';

export default [
    {
        name: 'Settings',
        path: '/admin/settings',
        component: () => import('@views/admin/settings/Index.vue'),
        meta: {
            breadcrumb: 'Администрирование / Общие настройки',
            auth: [ROLE_CODE_DIRECTOR, ROLE_CODE_SHIFT_SUPERVISOR],
        }
    },
]
