'use strict';

import {$app as App}                           from '../../../../main';
import {PLANNED_SANITARY_INSPECTIONS_ENDPOINT} from '../../../../utils/endpoints';

export default {
    namespaced: true,
    state: {
        count: null,
    },
    getters: {
        count: state => state.count
    },
    mutations: {
        setCount(state, count) {
            state.count = count
        }
    },
    actions: {
        refresh({commit}) {
            App.$http.get(`${PLANNED_SANITARY_INSPECTIONS_ENDPOINT}/count`,
                {
                    params: {
                        without_loading: true,
                    }
                }
            ).then((response) => {
                commit('setCount', response.data.count);
            });
        }
    }
}
