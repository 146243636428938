'use strict';

import {$app as App} from '../main.js';

class HttpClient {
    /**
     *
     * @param {String} url
     * @param {AxiosRequestConfig} config
     */
    get(url, config = {}) {
        return App.$http.get(url, config);
    }

    /**
     *
     * @param {String} url
     * @param {AxiosRequestConfig} config
     */
    post(url, config = {}) {
        return App.$http.post(url, config);
    }

    /**
     *
     * @param {String} url
     * @param {AxiosRequestConfig} config
     */
    put(url, config = {}) {
        return App.$http.put(url, config);
    }

    /**
     *
     * @param {String} url
     * @param {AxiosRequestConfig} config
     */
    delete(url, config = {}) {
        return App.$http.delete(url, config);
    }
}

export default new HttpClient();
