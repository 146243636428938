<template>
    <div>
        <template v-if="ErrorsBag.state()">
            <div slot="modal-header" class="w-100" style="margin-bottom:20px;">
                <b-row align-h="center">
                    <h4 class="danger-color">Произошла ошибка</h4>
                </b-row>
            </div>

            <b-row align-h="center" v-for="( error, index ) in ErrorsBag.list()" :key="index" class="error danger-color text-center" style="padding: 0px 10px;">
                <h5>{{ error }}</h5>
            </b-row>

            <b-row align-h="center" style="margin-top:20px;">
                <b-button @click="ErrorsBag.discard()" variant="info" style="color:#fff;">Вернуться</b-button>
            </b-row>
        </template>
    </div>
</template>

<script>
    import ErrorsBag from '../../utils/errorsBag';

    export default {
        name: "ModalError",
        data() {
            return { ErrorsBag };
        }
    }
</script>

<style scoped lang="sass">
    .danger-color
        color: #99101d
</style>
