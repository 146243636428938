<template>
    <select v-model="model" class="form-control" :disabled="disabled">
        <option :value="null" :disabled="!nullable">Выберите склад</option>
        <option v-for="option in options" :value="option.id">
            {{ option.name }}
        </option>
    </select>
</template>

<script>
import {StorageService} from "@services";

export default {
    props: {
        value: {},
        allowedRoles: {
            required: false,
            default: () => [],
        },
        selectableForTaskList: {
            type: Boolean,
            default: false,
        },
        material: {
            type: Boolean,
            required: false,
            default: null,
        },
        salaryEnabled: {
            type: Boolean,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'Склад'
        },
        nullable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        model: {
            get: function () {
                return this.value?.id ?? null;
            },
            set: function (value) {
                this.$emit('input', this.storages.find((storage) => storage.id === value));
            }
        },
        options() {
            return this.storages;
        },
    },
    data() {
        return {
            storages: [],
        }
    },
    methods: {
        getParams() {
            let params = {
                limit: 99,
                filters: {},
                sort: 'name',
                sortDirection: 'asc'
            };

            params.filters.allowed_roles = this.allowedRoles || [];

            if (this.material !== null) {
                params.filters.material = this.material;
            }

            if (this.salaryEnabled !== null) {
                params.filters.salary_enabled = this.salaryEnabled;
            }

            if (this.selectableForTaskList) {
                params.filters.selectable_for_task_list = 1;
            }

            return params;
        },
        loadStorages() {
            StorageService.index(this.getParams())
                .then(({data}) => this.storages = data)
                .catch(() => {
                    this.$bvToast.toast('Ошибка загрузки складов/цехов', {variant: 'danger'});
                });
        },
    },
    mounted() {
        this.loadStorages();
    }
}
</script>
