import {ROLE_ADMIN} from "@utils/Roles";

export default [
    {
        name: 'WeighingScalesList',
        path: '/weighing_scales',
        component: () => import('@components/weighing-scales/List'),
        meta: {
            label: 'Настройки весов',
            auth: [ROLE_ADMIN]
        }
    },
]
