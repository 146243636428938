import Router from './Router';

export const ERROR_PREFIX = 'TransitionError: ';

/**
 * Базовый класс перехода по ШК
 */
class Transition {
    /**
     * Инциализируем параметры перехода
     */
    constructor() {
        this._prefix = null;
        this._router = new Router();

        this._init();
        this._validation();
    }

    /**
     * Инициализируем переход. обязательно для заполнения
     * @private
     */
    _init() {
        throw new Error( ERROR_PREFIX + 'transition _init() is not defined' );
    }

    /**
     * Валидируем параметры перехода
     * @private
     */
    _validation() {
        if ( this._prefix === null || typeof this._prefix !== 'string' ) {
            throw new Error( ERROR_PREFIX + 'invalid transition prefix' );
        }
    }

    /**
     * Получаем сообщение об ошибке
     *
     * @param message
     * @return {string}
     */
    static _error( message ) {
        return ERROR_PREFIX + message;
    }

    /**
     * Получаем префикс перехода
     *
     * @return {null|string}
     */
    get prefix() {
        return this._prefix;
    }

    /**
     * Получаем обработчик маршрута
     *
     * @return {Router}
     */
    get router() {
        return this._router;
    }
}

export default Transition;
