<template>
    <div>
        <span v-if="toolbar === 'storage_senior_dashboard'">
            <storage-senior-toolbar></storage-senior-toolbar>
        </span>
    </div>
</template>

<script>
    import StorageSeniorToolbar from '../productiontask/StorageSeniorDashboard/HeaderToolbar';
    export default {
        name: "HeaderToolbar",
        components: {
            StorageSeniorToolbar
        },
        computed: {
            toolbar() {
                return this.$store.state.header_toolbar;
            }
        }
    }
</script>

<style scoped>

</style>
