import {ROLE_ACCOUNTANT, ROLE_ADMIN, ROLE_SHIFT_SUPERVISOR, ROLE_STOCKMAN, ROLE_STORAGE_SENIOR} from "@utils/Roles";

export default [
    {
        name: 'MaterialTransferActList',
        path: '/material-transfer-acts/',
        component: () => import('@components/materialtransferact/MaterialTransferList'),
        meta: {
            label: 'Акты передачи',
            auth:  [ROLE_ADMIN, ROLE_STORAGE_SENIOR, ROLE_STOCKMAN, ROLE_ACCOUNTANT],
            fullsize: true
        }
    },
    {
        name: 'MaterialTransferActReceiveList',
        path: '/material-transfer-acts/receive',
        component: () => import('@components/materialtransferact/MaterialTransferActReceiveList'),
        meta: {
            label: 'Приемка',
            auth:  [ROLE_ADMIN, ROLE_STORAGE_SENIOR, ROLE_STOCKMAN, ROLE_SHIFT_SUPERVISOR],
            fullsize: true
        }
    },
    {
        name: 'MaterialTransferActCreate',
        path: '/material-transfer-acts',
        component: () => import('@components/materialtransferact/MaterialTransferForm'),
        meta: {
            label: 'Акт передачи нов.',
            auth:  [ROLE_ADMIN, ROLE_STORAGE_SENIOR, ROLE_STOCKMAN, ROLE_SHIFT_SUPERVISOR, ROLE_ACCOUNTANT],
        }
    },
    {
        name: 'MaterialTransferActUpdate',
        path: '/material-transfer-acts/:id',
        component: () => import('@components/materialtransferact/MaterialTransferForm'),
        meta: {
            label: 'Акт передачи',
            auth:  [ROLE_ADMIN, ROLE_STORAGE_SENIOR, ROLE_STOCKMAN, ROLE_SHIFT_SUPERVISOR, ROLE_ACCOUNTANT],
        }
    },
    {
        name: 'MaterialTransferActReceive',
        path: '/material-transfer-acts/receive/:id',
        component: () => import('@components/materialtransferact/MaterialTransferReceiveForm'),
        props: true,
        meta: {
            label: 'Приемка',
            auth:  [ROLE_ADMIN, ROLE_STORAGE_SENIOR, ROLE_STOCKMAN, ROLE_SHIFT_SUPERVISOR],
        }
    },
    {
        name: 'PackTransferInterface',
        path: '/material-transfer-acts/pack-transfer',
        component: () => import('@components/materialtransferact/PackTransferInterface'),
        meta: {
            label: 'Лотки и этикетки',
            auth:  [ROLE_ADMIN, ROLE_STORAGE_SENIOR, ROLE_STOCKMAN, ROLE_SHIFT_SUPERVISOR],
            fullsize: true
        }
    }
]
