import Vue from 'vue'
import VueResource from 'vue-resource'
import App from './components/App';

import store from './store';
import router from './router'

import '@progress/kendo-ui';
import '@progress/kendo-theme-default/dist/all.css';
import '@progress/kendo-ui/js/cultures/kendo.culture.ru-RU.js';
import 'vue-datetime/dist/vue-datetime.css';
import 'izitoast/dist/css/iziToast.min.css';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import 'vue2-datepicker/locale/ru';
import VueIziToast from 'vue-izitoast';
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';

import { SchedulerInstaller } from '@progress/kendo-scheduler-vue-wrapper';
import { DateinputsInstaller } from '@progress/kendo-dateinputs-vue-wrapper';

import { API_HOST, API_PATH } from "./config/_entrypoint";
import BarcodeRepository from './utils/BarcodeRepository.js';
import VModal from 'vue-js-modal';

import Loader from './utils/loader';

import Auth from '@websanova/vue-auth';
import authConfig from './config/auth';

/**
 * У наших ТСД устаревший WebView
 * Он не поддерживает метод Object.values
 * Вследствие чего падает Core UI
 * Импортируем этот пакет для быстрого фикса
 */
if (!Object.values) {
    const values = require('object.values');
    values.shim();
}

kendo.culture('ru-RU');

Vue.use(VueIziToast, {timeout: 10000});
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueResource);
Vue.use(SchedulerInstaller);
Vue.use(DateinputsInstaller);
Vue.use(BarcodeRepository);
Vue.use(VModal, { dynamic: true, injectModalsContainer: true });

Vue.http.options.root = API_HOST + API_PATH;

Vue.http.interceptors.push(request => {
    const withoutLoader = request.params.without_loading;
    delete request.params.without_loading;

    if (!withoutLoader) {
        Loader.add();
    }

    return response => {
        if (!withoutLoader) {
            Loader.remove();
        }

        if (response.status === 401 && this.$app.$route.name !== 'Login') {
            this.$app.$modal.show('login');
        }
    };
});

Vue.router = router;
Vue.use(Auth, authConfig);

/* eslint-disable no-new */
export const $app = new Vue({
    ...App,
    router,
    store,
}).$mount('#app');
