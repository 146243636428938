<template>
    <div>
        <template v-if="isInvalid">
            <div slot="modal-header" class="w-100" style="margin-bottom:20px;">
                <b-row align-h="center">
                    <h3 class="danger-color">Не хватает сырья</h3>
                </b-row>
            </div>

            <b-card
                no-body
                class="w-100"
                style="border:0;"
                :key="nomenclatureIndex"
                v-for="( nomenclature, nomenclatureIndex ) in missingNomenclatures"
            >
                <b-card-header header-tag="header" class="p-0" role="tab" style="background-color:#fff;border:0;">
                    <div
                        v-b-toggle="`nomenclature_` + nomenclatureIndex"
                        class="btn btn-outline-danger nowrap-word-wrapper"
                    >
                        <b-row>
                            <b-col md="7" class="text-left break-long-words">{{ nomenclature.name }}</b-col>
                            <b-col md="5" class="text-right break-long-words">
                                <div>
                                    Требуется:
                                        {{ parseFloat( nomenclature.count ).toFixed( 4 ) }}&nbsp;
                                        {{ nomenclature.measureUnit.name }}.
                                </div>
                                <div>
                                    На складе:
                                        {{ remainsCount( nomenclature ).toFixed( 4 ) }}&nbsp;
                                        {{ nomenclature.measureUnit.name }}.
                                </div>
                                <div>
                                    Осталось:
                                        {{ requiredCount( nomenclature ).toFixed( 4 ) }}&nbsp;
                                        {{ nomenclature.measureUnit.name }}.
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-card-header>

                <b-collapse
                    role="tabpanel"
                    class="text-left"
                    accordion="raw-material"
                    :id="`nomenclature_` + nomenclatureIndex"
                >
                    <b-card-body>
                        <b-row>
                            <b-col md="12" v-if="unreceivedMaterialTransferActs( nomenclature ).length > 0">
                                <h5 class="text-center">Непринятые передачи</h5>
                            </b-col>
                            <b-col md="12" v-else>
                                <h5 class="text-center">Непринятые передачи отсутствуют!</h5>
                            </b-col>
                        </b-row>

                        <div :key="actIndex" v-for="( act, actIndex ) in unreceivedMaterialTransferActs( nomenclature )">
                            <hr class="my-2" />
                            <b-row>
                                <b-col md="7" class="text-left break-long-words">
                                    <b-link @click="goToMaterialTransferAct( act )">
                                        {{ nomenclature.name }}&nbsp;-&nbsp;
                                        <b>
                                            {{ materialTransferActCount( act, nomenclature ) }}&nbsp;
                                            {{ nomenclature.measureUnit.name }}.
                                        </b>
                                    </b-link>
                                </b-col>

                                <b-col md="5" class="text-right break-long-words">
                                    <template v-if="materialTransferActIsReceived( act )">
                                        <b class="success-color">Принято</b>
                                    </template>
                                    <template v-else>
                                        <b-button
                                            variant="outline-success"
                                            @click="receiveMaterialTransferAct( act, nomenclature )"
                                        >Принять</b-button>
                                    </template>
                                </b-col>
                            </b-row>
                        </div>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </template>
        <template v-else-if="!Loader.state() && !ErrorsBag.state()">
            <b-row align-h="center" class="text-center" style="padding:0 10px;">
                <h3 class="success-color">Вы уверены, что хотите выполнить данное действие?</h3>
            </b-row>
        </template>
    </div>
</template>

<script>
    import moment from 'moment';

    import map from 'lodash/map';
    import find from 'lodash/find';
    import sumBy from 'lodash/sumBy';
    import filter from 'lodash/filter';
    import forEach from 'lodash/forEach';
    import findIndex from 'lodash/findIndex';
    import cloneDeep from 'lodash/cloneDeep';

    import Loader from '../../../../utils/loader';
    import ErrorsBag from '../../../../utils/errorsBag';

    import { NOMENCLATURES_ENDPOINT, MATERIAL_TRANSFER_ACT_ENDPOINT } from '../../../../utils/endpoints';

    const EPSILON = 0.0001;
    const MATERIAL_TRANSFER_ACT_RECEIVE = 'MaterialTransferActReceive';

    export default {
        name: "MissingRawMaterials",
        props: {
            date: { type: String, default: null },
            value: { type: Array, default: () => [] },
            storageId: { type: Number, default: null },
        },
        data() {
            return {
                Loader,
                ErrorsBag,

                remains: [],
                rawMaterials: [],
                missingNomenclatures: [],
                materialTransferActs: [],
                receivedMaterialTransferActs: [],
            };
        },
        watch: {
            value() {
                this.rawMaterials = cloneDeep( this.value );
            },
            rawMaterials() {
                this.clear();

                if ( this.rawMaterials.length > 0 && this.storageId !== null ) {
                    this.fetchRawMaterialsRemains();
                }
            }
        },
        computed: {
            remainsDate() {
                let momentDate = this.date ? moment( this.date ) : moment() ;
                return momentDate.format( 'YYYY-MM-DD H:m:s' );
            },
            storage() {
                return { id: this.storageId };
            },
            isInvalid() {
                return this.missingNomenclatures.length > 0 && !Loader.state() && !ErrorsBag.state();
            },
            nomenclatureIds() {
                return map( this.rawMaterials, rawMaterial => rawMaterial.nomenclature.id );
            }
        },
        methods: {
            clear() {
                ErrorsBag.discard();

                this.remains = [];
                this.missingNomenclatures = [];
                this.materialTransferActs = [];
                this.receivedMaterialTransferActs = [];
            },

            goToMaterialTransferAct( act ) {
                this.$router.push( { name: MATERIAL_TRANSFER_ACT_RECEIVE, params: { id: act.id } } );
                this.$emit( 'goTo' );
            },
            materialTransferActIsReceived( act ) {
                return !!find( this.receivedMaterialTransferActs, receivedAct => receivedAct.id === act.id );
            },

            remainsCount( nomenclature ) {
                let nomenclatureRemains = find(
                    this.remains,
                    remain => remain.nomenclature.id === nomenclature.id && remain.remains.nomenclature_lot !== null
                );

                let remainsCount = parseFloat( sumBy( nomenclatureRemains.remains, 'count' ) );
                remainsCount += this.receivedNomenclatureCount( nomenclature );

                return parseFloat( remainsCount );
            },
            requiredCount( nomenclature ) {
                let requiredCount = nomenclature.count - this.remainsCount( nomenclature );

                return parseFloat( requiredCount <= EPSILON ? 0 : requiredCount );
            },
            receivedNomenclatureCount( nomenclature ) {
                let receivedNomenclatureCount = sumBy(
                    this.receivedMaterialTransferActs,
                    act => {
                        let receivedNomenclatures =
                            filter( act.items, item => item.nomenclature_lot.nomenclature_id === nomenclature.id );

                        if ( receivedNomenclatures.length > 0 ) {
                            return sumBy( receivedNomenclatures, 'count' );
                        }
                    }
                );

                return parseFloat( receivedNomenclatureCount );
            },
            materialTransferActCount( act, nomenclature ) {
                let nomenclatureActs =
                    filter( act.items, item => item.nomenclature_lot.nomenclature_id === nomenclature.id );

                let materialTransferActCount = sumBy( nomenclatureActs, 'count');

                return parseFloat( materialTransferActCount );
            },

            unreceivedMaterialTransferActs( nomenclature ) {
                return map( this.materialTransferActs, act => {
                    let foundedNomenclature =
                        find( act.items, item => item.nomenclature_lot.nomenclature_id === nomenclature.id );

                    if ( !!foundedNomenclature ) {
                        return act;
                    }
                } );
            },

            fetchRawMaterialsRemains() {
                let params = {
                    dateTo: this.remainsDate,
                    storage: { id: this.storage.id },
                    nomenclatureIds: this.nomenclatureIds,
                };

                this.$http
                    .get( NOMENCLATURES_ENDPOINT + '/remains', { params: params } )
                    .then( response => {
                        this.remains = map( response.data.data, nomenclature => {
                            return {
                                nomenclature: nomenclature,
                                remains: filter( nomenclature.remains, remain => remain.nomenclature_lot )
                            };
                        } );

                        let materialsGroupByNomenclature = {};
                        forEach( this.rawMaterials, rawMaterial => {
                            let nomenclature = rawMaterial.nomenclature;

                            if ( !materialsGroupByNomenclature.hasOwnProperty( nomenclature.id ) ) {
                                materialsGroupByNomenclature[ nomenclature.id ] = nomenclature;
                                materialsGroupByNomenclature[ nomenclature.id ].count = 0;
                            }

                            materialsGroupByNomenclature[ nomenclature.id ].count += parseFloat( rawMaterial.count );
                        } );

                        forEach( materialsGroupByNomenclature, nomenclature => {
                            if ( ( this.remainsCount( nomenclature ) - nomenclature.count ) < -EPSILON ) {
                                this.missingNomenclatures.push( nomenclature );
                            }
                        } );

                        if ( this.missingNomenclatures.length > 0 ) {
                            this.fetchUnreceivedMaterialTransferActs();
                        }
                    }, response => ErrorsBag.fill( response.body ) );
            },
            fetchUnreceivedMaterialTransferActs() {
                let params = {
                    storage: { id: this.storage.id },
                    nomenclature_ids: map( this.missingNomenclatures, nomenclature => nomenclature.id )
                };

                this.$http
                    .get( MATERIAL_TRANSFER_ACT_ENDPOINT + '/unreceived', { params: params } )
                    .then( response => {
                        this.materialTransferActs = response.data.data;
                    }, response => ErrorsBag.fill( response.body ) );
            },

            receiveMaterialTransferAct( act, nomenclature ) {
                this.$http
                    .put( MATERIAL_TRANSFER_ACT_ENDPOINT + '/' + act.id + '/receive', { received: true } )
                    .then( () => {
                        this.receivedMaterialTransferActs.push( act );

                        if ( this.requiredCount( nomenclature ) <= 0 ) {
                            let missingNomenclatureIndex = findIndex(
                                this.missingNomenclatures,
                                missingNomenclature => missingNomenclature.id === nomenclature.id
                            );

                            this.missingNomenclatures.splice( missingNomenclatureIndex, 1 );
                        }
                    }, response => ErrorsBag.fill( response.body ) );
            }
        }
    }
</script>

<style scoped lang="sass">
    .danger-color
        color: #99101d

    .success-color
        color: #2d7e46

    .nowrap-word-wrapper
        white-space: normal
        display: block !important

    .break-long-words
        hyphens: auto
        -moz-hyphens: auto
        -webkit-hyphens: auto
        word-break: break-all
        word-break: break-word
        -ms-word-break: break-all
</style>
