import { ROLE_DIRECTOR, ROLE_STOCKMAN_SENIOR } from '@utils/Roles'

export default [
    {
        name: 'FoodCost',
        path: '/analytics/food-cost',
        component: () => import('@components/analytics/FoodCost'),
        meta: {
            label: 'Аналитика / Фудкост',
            auth: [ROLE_DIRECTOR, ROLE_STOCKMAN_SENIOR],
            fullsize: true
        }
    },
    {
        name: 'OperationCost',
        path: '/analytics/operation-cost',
        component: () => import('@components/analytics/OperationCost'),
        meta: {
            label: 'Аналитика / Стоимость операций',
            auth: [ROLE_DIRECTOR, ROLE_STOCKMAN_SENIOR],
            fullsize: true
        }
    }
]
