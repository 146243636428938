import {ROLE_ADMIN, ROLE_SHIFT_SUPERVISOR} from "@utils/Roles";

export default [
    {
        name: 'ShiftUsage',
        path: '/report/shift/usage',
        component: () => import('@components/shiftreport/Usage'),
        meta: {
            label: 'НОСП / Отчет по сырью',
            auth: [ROLE_ADMIN, ROLE_SHIFT_SUPERVISOR],
            fullsize: true
        }
    }
]
