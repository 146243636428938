import {ROLE_ADMIN, ROLE_GROUP_SENIOR, ROLE_LABELER, ROLE_PLANNER} from "@utils/Roles";

export default [
    {
        name: 'ImportDashboard',
        path: '/production-orders/import',
        component: () => import('@components/production-order/ImportDashboard'),
        meta: {
            label: 'Импорт заказов',
            auth:  [ROLE_ADMIN]
        }
    }
]
