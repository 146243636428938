'use strict';

import { ROLE_ADMIN, ROLE_STOCKMAN, ROLE_STOCKMAN_SENIOR } from "@utils/Roles";

export default [
    {
        name: 'CorrectionActs',
        path: '/correction-acts',
        component: () => import('@views/correction-acts/Index'),
        meta: {
            label: 'Склад / Акты коррекции',
            auth: [ ROLE_ADMIN, ROLE_STOCKMAN, ROLE_STOCKMAN_SENIOR],
        }
    },
]
