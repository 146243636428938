import ProductionActByTaskTransition from './ProductionActByTaskTransition';

export {
    ProductionActByTaskTransition
};

const TRANSITIONS_LIST = [
    ProductionActByTaskTransition
];

export default TRANSITIONS_LIST;
